import React from 'react'
import api from 'configs/api'
import { alertSuccess, alertDanger } from 'utils/toast'
import { translate } from 'locales'

export const findUserData = async (
  setUserData: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const find = await api.get('/doctors/v1/users/me')

    const dataStorage: any = window.localStorage.getItem('@StarBem:user')
    const dataParse = JSON.parse(dataStorage)

    const specialties: { id: string; name: string }[] = []
    find.data.doctor.specialties.forEach((item: any) => {
      const temp = {
        id: item.id,
        name: item.name,
        type: 1,
        status: item.status,
      }

      if (item.status === 'active') {
        specialties.push(temp)
      }
    })

    const data = {
      ...dataParse,
      ...find.data.doctor,
      specialties,
    }

    setUserData({
      ...dataParse,
      ...data,
    })

    window.localStorage.setItem(
      '@StarBem:user',
      JSON.stringify({ ...dataParse, ...data })
    )

    setLoading(false)
  } catch (err: any) {
    alertDanger(
      err?.response?.data?.message || translate('errors.errorLoadingData')
    )
    setLoading(false)
  }
}

export const handleAreaSelection = (
  e: any,
  area: any,
  userData: any,
  setUserData: React.Dispatch<React.SetStateAction<any>>
) => {
  const updatedOccupations = userData.specialties.filter(
    (item: any) => item.id !== area.id
  )

  if (e.target.checked) {
    updatedOccupations.push({
      id: area.id,
      name: area.name,
      type: 1,
    })
  } else {
    updatedOccupations.push({ id: area.id, name: area.name, type: 0 })
  }

  setUserData({ ...userData, specialties: updatedOccupations })
}

export const updateUserData = async (
  data: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setNewPassword: React.Dispatch<React.SetStateAction<string>>,
  setNewPasswordConfirm: React.Dispatch<React.SetStateAction<string>>
) => {
  setLoading(true)
  // eslint-disable-next-line

  const body = data.password
    ? {
        cellphone: data.profile.cellphone,
        gender: data.profile.gender,
        password: data.password,
      }
    : {
        cellphone: data.profile.cellphone,
        gender: data.profile.gender,
      }

  try {
    await api.put(`/doctors/v1/users/${data.id}`, body)

    alertSuccess(translate('success.dataUpdate'))
    setLoading(false)
    setNewPassword('')
    setNewPasswordConfirm('')
  } catch (err: any) {
    setLoading(false)
    alertDanger(
      err?.response?.data?.message || translate('errors.internalError')
    )
  }
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { Pagination } from '@material-ui/lab'

import { translate } from 'locales'

import { CardDate } from 'components'
import noData from 'assets/images/no-data.svg'
import TimeLineResume from './TimeLineResume'

import * as S from './styles'
import * as N from '../New/styles'

moment.locale('pt-br', {
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
})

const useStyles = makeStyles((theme: any) => ({
  timeline: {
    padding: 0,
  },
  left: {
    flex: 'none',
    paddingLeft: 0,
  },
  right: {
    paddingRight: 0,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    '@media(max-width: 740px)': {
      flexDirection: 'column',
      gap: '10px',
    },
  },
}))

const Resume = ({ medicalRecords, count, setPage }: any) => {
  const classes = useStyles()

  return (
    <>
      <Timeline align="left" className={classes.timeline}>
        {medicalRecords && Object.keys(medicalRecords).length === 0 && (
          <N.NoData>
            <img
              src={noData}
              alt="Icone simbolizando que não existe prontuarios a serem exibidas"
            />
            <span>{translate('medicalRecord.notMedicalRecord')}</span>
          </N.NoData>
        )}

        {medicalRecords &&
          Object.keys(medicalRecords)
            .reverse()
            ?.map((item) => (
              <TimelineItem key={item}>
                <TimelineOppositeContent className={classes.left}>
                  <CardDate
                    day={moment.utc(item).format('DD')}
                    month={moment.utc(item).format('MMM')}
                    year={moment.utc(item).format('YYYY')}
                  />
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className={classes.right}>
                  {medicalRecords[item].map((i: any) => (
                    <Accordion key={i.uuid}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={i.uuid}
                      >
                        <div
                          className={classes.heading}
                          style={{
                            marginTop: 0,
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>
                            {`${translate('medicalRecord.by')} Dr(a). ${
                              i?.scheduling?.doctor?.name
                            }`}
                            <Typography>
                              {`${translate('schedule.scheduleDate')}: ${moment(
                                i.scheduling.from_date_time
                              ).format('DD/MM/YYYY')}`}
                            </Typography>
                          </Typography>
                        </div>
                        <div>
                          <Typography>
                            {`(${i.scheduling?.specialty_name})`}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ display: 'block', padding: 0 }}
                      >
                        <TimeLineResume
                          history={i}
                          prescriptions={i.scheduling?.prescriptions}
                          exams={i.scheduling?.exams}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </TimelineContent>
              </TimelineItem>
            ))}

        <S.ContentPagination>
          {count > 1 && <Pagination count={count} onChange={setPage} />}
        </S.ContentPagination>
      </Timeline>
    </>
  )
}

export default Resume

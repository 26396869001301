import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { telephoneMask } from 'js-essentials-functions'
import moment from 'moment'

import { translate } from 'locales'

// import { orderBy } from 'lodash'
import Pagination from '@material-ui/lab/Pagination'

// Components
import {
  Header,
  Card,
  Button,
  Loading,
  Footer,
  Search,
  Input,
} from 'components'
import * as Global from 'global/styles.global'

// Styles
import * as S from './styles'

import { findPatients } from './services'

const Patients = () => {
  const history = useHistory()
  const [numPage, setNumPage] = useState(1)
  const [maxPage, setMaxPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [patients, setPatients] = useState<Array<any>>([])
  const [name, setName] = useState('')
  const [date, setDate] = useState({
    start: '',
    end: '',
  })

  const limit = 10

  useEffect(() => {
    findPatients(
      setPatients,
      setLoading,
      setMaxPages,
      numPage,
      limit,
      name,
      '',
      ''
    )
    // eslint-disable-next-line
  }, [numPage])

  return (
    <Global.Container>
      {loading && <Loading title={translate('patients.loading')} />}
      <Header />
      <S.ContentPatients>
        <div className="patients-top">
          <h1 className="title-patients">{translate('patients.patients')}</h1>
          <div className="search-patients">
            <Search
              name="search-patient"
              placeholder={translate('patients.searchPatients')}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
            <Button
              color="primary"
              onClick={() => {
                setNumPage(1)
                findPatients(
                  setPatients,
                  setLoading,
                  setMaxPages,
                  1,
                  limit,
                  name
                )
              }}
              type="button"
              title="Buscar"
            >
              {translate('patients.search')}
            </Button>
            <Button
              color="primary-light"
              onClick={() => {
                setDate({
                  start: '',
                  end: '',
                })
                setName('')
                setNumPage(1)
                findPatients(setPatients, setLoading, setMaxPages, 1, limit, '')
              }}
              type="button"
              title="Limpar"
            >
              {translate('patients.clean')}
            </Button>
          </div>
        </div>
        <Card>
          <S.ContentHeader>
            <S.ContentInputs>
              <Input
                name="date"
                type="date"
                placeholder={translate('patients.dateInit')}
                label
                styles={{ marginLeft: 15 }}
                value={date.start}
                onChange={(e: any) =>
                  setDate({
                    ...date,
                    start: moment(e.target.value).format('YYYY-MM-DD'),
                  })
                }
              />
              <Input
                name="date"
                type="date"
                value={date.end}
                onChange={(e: any) =>
                  setDate({
                    ...date,
                    end: moment(e.target.value).format('YYYY-MM-DD'),
                  })
                }
                placeholder={translate('patients.dateEnd')}
                label
                styles={{ marginLeft: 15 }}
              />
              <Button
                color="primary-light"
                onClick={() => {
                  findPatients(
                    setPatients,
                    setLoading,
                    setMaxPages,
                    1,
                    limit,
                    name,
                    date.start,
                    date.end
                  )
                }}
                type="button"
                title="Filtrar"
                styles={{ marginLeft: 15 }}
              >
                {translate('patients.filter')}
              </Button>
            </S.ContentInputs>
          </S.ContentHeader>

          <S.TablePatients>
            <Table className="table-content" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="28%">
                    {translate('patients.name')}
                  </TableCell>
                  <TableCell width="18%">
                    {translate('patients.cellphone')}
                  </TableCell>
                  <TableCell width="18%">
                    {translate('patients.birthdate')}
                  </TableCell>
                  <TableCell width="18%">
                    {translate('patients.lastAppointment')}
                  </TableCell>
                  <TableCell width="18%" />
                </TableRow>
              </TableHead>
              <TableBody>
                {patients &&
                  patients?.map((patient) => (
                    <TableRow key={patient.user_id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="primary-col"
                      >
                        {patient.user_name}
                      </TableCell>
                      <TableCell align="left">
                        {telephoneMask(patient.cellphone || '')}
                      </TableCell>
                      <TableCell align="left">
                        {moment(patient.birthdate).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell align="left">
                        {moment
                          .utc(patient.last_appointment)
                          .format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          type="button"
                          color="primary-light"
                          onClick={() =>
                            history.push(`/prontuarios/${patient.user_id}`)
                          }
                          size="small"
                          styles={{ width: 100 }}
                        >
                          {translate('patients.view')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <S.Pagination>
              <Pagination
                count={maxPage}
                color="secondary"
                page={numPage}
                onChange={(e, v) => setNumPage(v)}
              />
            </S.Pagination>
          </S.TablePatients>
        </Card>
      </S.ContentPatients>
      <Footer />
    </Global.Container>
  )
}

export default Patients

/* eslint-disable */
import styled from 'styled-components'

export const Container = styled.div<{
  isFullScreen: boolean
  showConfig: boolean
  showDeviceConfig: boolean
  windowHeight: number
  windowWidth: number
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${({ isFullScreen, windowWidth }) =>
    isFullScreen ? `${windowWidth}px` : '100%'};
  height: ${({ isFullScreen, windowHeight }) =>
    isFullScreen ? `${windowHeight}px` : `100%`};
  overflow: hidden;
  position: ${({ isFullScreen }) => (isFullScreen ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  z-index: 10;

  @media (max-width: 480px) {
    width: 100%;
    height: ${({ isFullScreen }) => (isFullScreen ? '100vh' : '400px')};
    position: ${({ isFullScreen }) => (isFullScreen ? 'fixed' : 'relative')};
    top: 0;
    left: 0;
    right: 0;
    align-self: center;
    z-index: ${({ isFullScreen }) => (isFullScreen ? 999 : 1)};
  }
`

export const MainVideo = styled.div<{
  isFullScreen: boolean
  showConfig: boolean
  showDeviceConfig: boolean
  windowHeight: number
}>`
  width: 100%;
  height: ${({ isFullScreen, windowHeight }) =>
    isFullScreen ? '100%' : windowHeight > 900 ? '60%' : '80%'};
  background: #000;
  position: relative;
  z-index: 0;

  @media (max-width: 768px) {
    height: ${({ isFullScreen, showConfig, showDeviceConfig }) =>
      showConfig || showDeviceConfig
        ? '60%'
        : isFullScreen
        ? 'calc(100% - 80px)'
        : '60%'};
  }

  @media (max-width: 480px) {
    height: ${({ isFullScreen, showConfig, showDeviceConfig }) =>
      showConfig || showDeviceConfig
        ? '60%'
        : isFullScreen
        ? 'calc(100% - 60px)'
        : '90%'};
  }
`

export const VideoContainer = styled.div<{ isFullScreen: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: ${({ isFullScreen }) => (isFullScreen ? '0' : 'auto')};
`

export const LocalVideo = styled.div<{
  isMain: boolean
  paused: boolean
  isFullScreen: boolean
}>`
  width: ${({ isMain }) => (isMain ? '100%' : '80px')};
  height: ${({ isMain }) => (isMain ? '100%' : '110px')};
  background: #000;
  position: ${({ isMain }) => (isMain ? 'relative' : 'absolute')};
  bottom: ${({ isMain, isFullScreen }) =>
    isMain ? '0' : isFullScreen ? '90px' : '8px'};
  right: ${({ isMain }) => (isMain ? '0' : '8px')};
  border-radius: ${({ isMain }) => (isMain ? '0' : '8px')};
  overflow: hidden;
  z-index: ${({ isMain }) => (isMain ? '0' : '1')};
  cursor: pointer;
  border: ${({ paused, isMain }) =>
    !isMain && paused ? '2px solid red' : 'none'};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7), 0 0 20px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    width: ${({ isMain }) => (isMain ? '100%' : '100px')};
    height: ${({ isMain }) => (isMain ? '100%' : '140px')};
    bottom: ${({ isMain, isFullScreen }) =>
      isMain ? '0' : isFullScreen ? '70px' : '6px'};
    right: ${({ isMain }) => (isMain ? '0' : '6px')};
  }

  @media (max-width: 480px) {
    width: ${({ isMain }) => (isMain ? '100%' : '80px')};
    height: ${({ isMain }) => (isMain ? '100%' : '110px')};
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: ${({ isMain }) => (isMain ? 'cover' : 'cover')};
  }

  ${({ paused, isMain }) =>
    !isMain &&
    paused &&
    `
    &::before {
      content: 'Vídeo pausado...';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 1.5em;
      text-align: center;
    }
  `}
`

export const RemoteVideo = styled.div<{
  isMain: boolean
  isFullScreen: boolean
  paused: boolean
  disconnected: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isMain }) => (isMain ? '100%' : '120px')};
  height: ${({ isMain }) => (isMain ? '100%' : '160px')};
  background: ${({ isMain, paused, disconnected }) =>
    isMain ? (paused || disconnected ? '#000' : '#fff') : '#000'};
  position: ${({ isMain }) => (isMain ? 'relative' : 'absolute')};
  bottom: ${({ isMain }) => (isMain ? '0' : '8px')};
  right: ${({ isMain }) => (isMain ? '0' : '8px')};
  border-radius: ${({ isMain }) => (isMain ? '0' : '8px')};
  overflow: hidden;
  z-index: ${({ isMain }) => (isMain ? '0' : '1')};
  cursor: pointer;
  border: ${({ paused, disconnected, isMain }) =>
    !isMain && (paused || disconnected) ? '2px solid red' : 'none'};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7), 0 0 20px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    width: ${({ isMain }) => (isMain ? '100%' : '100px')};
    height: ${({ isMain }) => (isMain ? '100%' : '140px')};
    bottom: ${({ isMain }) => (isMain ? '0' : '6px')};
    right: ${({ isMain }) => (isMain ? '0' : '6px')};
  }

  video {
    height: 100%;
    object-fit: ${({ isMain, isFullScreen }) =>
      isMain && isFullScreen ? 'cover' : 'contain'};
    width: auto;
  }

  ${({ paused, disconnected }) =>
    (paused || disconnected) &&
    `
    &::before {
      content: '${
        disconnected
          ? 'Aguardando conexão do paciente...'
          : 'Vídeo do paciente pausado...'
      }';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 1.5em;
      text-align: center;
    }
  `}
`

export const MenuWrapper = styled.div<{ isFullScreen: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${({ isFullScreen }) => (isFullScreen ? '#fff' : 'transparent')};
`

export const Footer = styled.div<{ isFullScreen: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${({ isFullScreen }) => (isFullScreen ? '10px 0' : '0')};
  background: #fff;
  padding: 10px 0;
  z-index: 10;

  @media (max-width: 768px) {
    padding: ${({ isFullScreen }) => (isFullScreen ? '8px 0' : '10px 0')};
  }

  @media (max-width: 480px) {
    padding: ${({ isFullScreen }) => (isFullScreen ? '6px 0' : '10px 0')};
  }
`

export const UtilityButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    gap: 5px;
  }

  @media (max-width: 480px) {
    gap: 3px;
  }
`

const ButtonCircle = styled.button<{ disabled: boolean; active?: boolean }>`
  width: 40px;
  height: 40px;
  cursor: ${({ disabled, active }) =>
    disabled && !active ? 'not-allowed' : 'pointer'};
  border-color: transparent;
  border-radius: 50%;
  background-color: ${({ disabled, active }) =>
    disabled && !active ? '#d3d3d3' : '#0c1f36'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  z-index: 3;

  @media (max-width: 768px) {
    width: 36px;
    height: 36px;
  }

  @media (max-width: 480px) {
    width: 28px;
    height: 28px;
  }

  img {
    width: 18px;
    height: 18px;
    opacity: ${({ disabled, active }) => (disabled && !active ? 0.5 : 1)};

    @media (max-width: 768px) {
      width: 14px;
      height: 14px;
    }

    @media (max-width: 480px) {
      width: 10px;
      height: 10px;
    }
  }
`

export const FullScreenButton = styled(ButtonCircle)<{ active: boolean }>`
  background-color: ${({ disabled, active }) =>
    disabled && !active ? '#d3d3d3' : '#0c1f36'};
  border: 2px solid transparent;
  &:hover {
    background-color: ${({ disabled, active }) =>
      disabled && !active ? '#d3d3d3' : '#a8a8a8'};
    border: 2px solid rgba(168, 168, 168, 0.5);

    img {
      opacity: ${({ disabled, active }) => (disabled && !active ? 0.5 : 0.7)};
    }
  }

  img {
    width: 36px;
    height: 36px;

    @media (max-width: 768px) {
      width: 28px;
      height: 28px;
    }

    @media (max-width: 480px) {
      width: 20px;
      height: 20px;
    }
  }
`

export const SettingsButton = styled(ButtonCircle)<{ active: boolean }>`
  background-color: ${({ disabled, active }) =>
    disabled && !active ? '#d3d3d3' : '#0c1f36'};
  border: 2px solid transparent;
  &:hover {
    background-color: ${({ disabled, active }) =>
      disabled && !active ? '#d3d3d3' : '#a8a8a8'};
    border: 2px solid rgba(168, 168, 168, 0.5);

    img {
      opacity: ${({ disabled, active }) => (disabled && !active ? 0.5 : 0.7)};
    }
  }

  img {
    width: 20px;
    height: 20px;

    @media (max-width: 768px) {
      width: 28px;
      height: 28px;
    }

    @media (max-width: 480px) {
      width: 20px;
      height: 20px;
    }
  }
`

export const ToggleButton = styled(ButtonCircle)<{ active: boolean }>`
  background-color: ${({ active, disabled }) =>
    disabled && !active ? '#d3d3d3' : active ? '#ff4d4f' : '#0c1f36'};
  &:hover {
    background-color: ${({ active, disabled }) =>
      disabled && !active
        ? '#d3d3d3'
        : active
        ? '#ff4d4f'
        : 'rgba(168, 168, 168, 0.5)'};
  }
`

export const EndButton = styled(ButtonCircle)<{ disabled: boolean }>`
  background-color: ${({ disabled, active }) =>
    disabled && !active ? '#d3d3d3' : '#ff4242'};
  &:hover {
    background-color: ${({ disabled, active }) =>
      disabled && !active ? '#d3d3d3' : '#a30e2d'};
  }
`

export const ConfigWrapper = styled.div<{ isFullScreen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 8px 0;
  }

  @media (max-width: 480px) {
    padding: 6px 0;
    bottom: ${({ isFullScreen }) => (isFullScreen ? '40px' : '0')};
  }
`

export const ConfigButtonWrapper = styled.div<{ isFullScreen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ isFullScreen }) =>
    isFullScreen ? 'center' : 'flex-start'};
  gap: 8px;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 768px) {
    gap: 6px;
  }

  @media (max-width: 480px) {
    gap: 4px;
  }
`

export const ConfigButton = styled.button<{ selected: boolean }>`
  width: 70px;
  height: 70px;
  border-radius: 12px;
  border: ${({ selected }) =>
    selected ? '2px solid #FFA500' : '2px solid transparent'};
  padding: 0;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 480px) {
    width: 50px;
    height: 50px;
  }
`

export const SaveButton = styled.button<{ isFullScreen: boolean }>`
  width: ${({ isFullScreen }) => (isFullScreen ? '350px' : '100%')};
  height: ${({ isFullScreen }) => (isFullScreen ? '40px' : '40px')};
  padding: ${({ isFullScreen }) => (isFullScreen ? '6px 12px' : '10px 18px')};
  gap: ${({ isFullScreen }) => (isFullScreen ? '0px' : '8px')};
  border-radius: ${({ isFullScreen }) =>
    isFullScreen ? '8px 8px 0 0' : '8px'};
  background: #ffa947;
  color: #fff;
  opacity: 1;
  margin-top: 10px;
  border: none;

  @media (max-width: 768px) {
    height: ${({ isFullScreen }) => (isFullScreen ? '44px' : '40px')};
  }

  @media (max-width: 480px) {
    height: ${({ isFullScreen }) => (isFullScreen ? '44px' : '36px')};
  }
`

export const DeviceSelectWrapper = styled.div<{ isFullScreen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ isFullScreen }) => (isFullScreen ? '350px' : '100%')};
  padding: 10px;
  background: #f1f1f1;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 8px;
  }

  @media (max-width: 480px) {
    padding: 6px;
  }
`

export const Label = styled.label`
  margin-bottom: 8px;
  font-size: 1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`

export const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;

  @media (max-width: 768px) {
    padding: 6px;
  }

  @media (max-width: 480px) {
    padding: 4px;
  }
`

// eslint-disable
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid, Modal } from '@material-ui/core'
import { normalizeName } from 'utils'
import createPersistedState from 'use-persisted-state'
import moment from 'moment'
import {
  formatDistanceToNow,
  addMinutes,
  subHours,
  differenceInMilliseconds,
  parseISO,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { telephoneMask } from 'js-essentials-functions'

import { calcIMC } from 'utils/convert'

// Utils
import { alertSuccess } from 'utils/toast'
import * as Global from 'global/styles.global'
import {
  Header,
  Card,
  Button,
  Loading,
  ModalShow,
  Alert,
  VideoCallSdk,
} from 'components'
import whatsappIcon from 'assets/icons/whatsapp.svg'
import videoIcon from 'assets/icons/video.svg'
import getAge from 'utils/getAge'
import useStorage from 'hooks/storageSync'
import { translate } from 'locales'
import StarService from 'components/Modal/StartService'
import { menuItems } from './utils'

import { IAccompaniment } from './interfaces'
// Styles
import * as S from './styles'

// Components
import ChatPatient from './ChatPatient'
import Anamnese from './Anamnese'
import ComplementarExams from './ComplementarExams'
import DiagnosticHypothesis from './DiagnosticHypothesis'
import Conduct from './Conduct'
import ExamsAndProcedures from './ExamsAndProcedures'
import Prescriptions from './Prescriptions'
import ImagesAndAttachments from './ImagesAndAttachments'
import History from './History'
import FollowUp from './FollowUp'

// Services
import {
  findSchedule,
  handlePrescription,
  savePrescription,
  initAndCancelSchedule,
  initVideoCall,
  saveMedicalRecord,
  createAccompaniment,
  sendCallPush,
} from './services'

const NewMedicalRecord = () => {
  const topContentRef = useRef<HTMLDivElement>(null)
  const params: any = useParams()
  const history = useHistory()

  const [storage]: any = useStorage(params.scheduleUUID, '')

  const useSchedulingStorage = createPersistedState(
    `starbem:scheduling:${params.scheduleUUID}`
  )

  const defaultAccompaniment: IAccompaniment = {
    state: 'sim',
    reason: '',
    day: '',
  }

  // State
  const [scheduling, setScheduling]: any = useSchedulingStorage({})
  const [cancelDescription, setCancelDescription] = useState('')
  const [showCancelModal, setShowCancelModal] = useState('none')
  const [showFinishModal, setShowFinishModal] = useState('none')
  const [showInitModal, setShowInitModal] = useState('none')
  const [selectedMenu, setSelectedMenu] = useState(1)
  const [loading, setLoading] = useState(true)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [accompaniment, setAccompaniment] = useState<IAccompaniment>(
    defaultAccompaniment
  )
  const [token, setToken] = useState('')
  const [state, setState] = useState({
    showModal: false,
    message: '',
    url: '',
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null)

  const isBr = scheduling?.patient?.address?.country === 'BR'

  const verifySavedCallToken = async () => {
    const callToken = localStorage.getItem('callToken')
    const callAppointmentId = localStorage.getItem('callAppointmentId')

    if (callToken && callAppointmentId === params.scheduleUUID) {
      setToken(callToken)
    }
  }

  useEffect(() => {
    const handleSize = () => {
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleSize)

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = subHours(new Date(), 3)
      const start = parseISO(scheduling?.from_date_time)
      const difference = differenceInMilliseconds(start, now) - 3 * 60 * 1000

      if (difference <= 0) {
        setIsButtonDisabled(false)
        setTimeRemaining(0)
      } else {
        setTimeRemaining(difference)
        if (difference <= 0) {
          setIsButtonDisabled(false)
        } else {
          setIsButtonDisabled(true)
        }
      }
    }

    calculateTimeRemaining()

    const intervalId = setInterval(() => {
      calculateTimeRemaining()
    }, 1000)

    return () => clearInterval(intervalId)
  }, [scheduling?.from_date_time])

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60
    const remaining = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`

    if (milliseconds <= 0) {
      return {
        duration: 'menos de 3 minutos',
        remaining,
      }
    }

    const duration = formatDistanceToNow(
      addMinutes(new Date(), milliseconds / 60000),
      { includeSeconds: false, locale: ptBR }
    )

    return {
      duration,
      remaining,
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    findSchedule(params.scheduleUUID, setScheduling, setLoading)

    verifySavedCallToken()
  }, [])

  const verifyWidth = window.screen.width > 500

  /* eslint-disable */
  useEffect(() => {
    const eventExec = async (event: any) => {
      const validEvents = ['cancel', 'excluded', 'prescricao']

      if (validEvents.includes(event.data.type)) {
        setState({ ...state, showModal: false })

        if (event.data.type === 'excluded') {
          return alertSuccess(translate('medicalRecord.removeSuccess'))
        }

        if (event.data.type === 'cancel') {
          return alertSuccess(translate('medicalRecord.cancelSuccess'))
        }

        if (event.data.type === 'prescricao') {
          await savePrescription(
            event.data,
            params.scheduleUUID,
            setScheduling,
            setLoading
          )
          return alertSuccess(translate('medicalRecord.finishedSuccess'))
        }
      }
    }

    window.addEventListener('message', eventExec, false)

    return () => {
      window.removeEventListener('message', eventExec, false)
    }
  }, [window.addEventListener])
  /* eslint-enable */

  const hideModal = () => {
    // @ts-ignore
    setShowCancelModal('none')
  }

  const hideFinishModal = () => {
    setShowFinishModal('none')
  }

  const hideInitModal = () => {
    setShowInitModal('none')
  }

  const handleInitService = useCallback(() => {
    initAndCancelSchedule({
      scheduleUUID: scheduling?.id,
      data: {
        status: 'started',
      },
      setSchedule: setScheduling,
      setLoading,
      history,
      action: null,
    })
  }, [history, scheduling, setScheduling])

  const handleCancelSchedule = useCallback(() => {
    initAndCancelSchedule({
      scheduleUUID: scheduling?.id,
      data: {
        status: 'canceled',
        cancel_description: cancelDescription,
      },
      setSchedule: setScheduling,
      setLoading,
      history,
      action: null,
    })
    // eslint-disable-next-line
  }, [history, scheduling, setScheduling, cancelDescription])

  const handleOpenWhatsApp = () => {
    window.open(
      `https://wa.me/${isBr ? '55' : '1'}${scheduling?.cellphone}`,
      '_blank'
    )
  }

  const handleSaveMedicalRecord = async () => {
    setShowFinishModal('none')

    const hasDiabetes =
      scheduling?.patient?.medical_record?.Medical_record_heath_problem?.includes(
        'Diabetes'
      ) || scheduling?.patient?.medical_record?.diabetic === true
        ? 'sim'
        : 'nao'

    const data = {
      user_uuid: scheduling?.patient?.id,
      schedule_uuid: scheduling?.id,
      diabetes: hasDiabetes,
      ...storage?.anamnese,
      ...storage?.complementarExams,
      ...storage?.conduct,
      ...storage?.diagnostic,
      ...storage?.vitalSigns,
    }

    if (accompaniment.state === 'sim') {
      const datas = {
        scheduling_id: scheduling?.id,
        scheduling_date: scheduling?.from_date_time,
        doctor_id: scheduling?.doctor?.id,
        doctor_name: scheduling?.doctor?.name,
        specialty_id: scheduling?.specialty_id,
        specialty_name: scheduling?.specialty_name,
        patient_id: scheduling?.patient?.id,
        patient_name: scheduling?.patient?.name,
        patient_cellphone: scheduling?.cellphone,
        reason_accompaniment: accompaniment.reason,
        date_accompaniment: `${accompaniment.day} 00:00:00`,
      }
      const res = await createAccompaniment(datas, data)
      if (!res) {
        return
      }
    }

    saveMedicalRecord(
      scheduling?.patient?.id,
      scheduling?.id,
      data,
      history,
      setLoading
    )
  }

  const handleScrollToTop = () => {
    if (topContentRef.current) {
      setTimeout(() => {
        topContentRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }

  const userSusWhatsapp =
    scheduling?.clinic?.attendence === 'whatsapp' &&
    !scheduling?.plan_associate_id &&
    !scheduling?.product_purchased_id

  const userSusVideo =
    scheduling?.clinic?.attendence === 'aplication' &&
    !scheduling?.plan_associate_id &&
    !scheduling?.product_purchased_id

  const doctorName = scheduling?.doctor?.name
  const doctorGender = scheduling?.doctor?.gender
  const doctorNameShow =
    doctorGender === 'male' ? `Dr. ${doctorName}` : `Dra. ${doctorName}`

  const verifyScrren: any = window.screen.width < 600

  return (
    <S.Container>
      <div ref={topContentRef} />
      {loading && (
        <Loading
          title={state.message || translate('medicalRecord.loadingData')}
        />
      )}
      <Header openNewTab={!!token} onCloseRoom={() => setToken('')} />
      <S.Content>
        <S.LeftContainer
          windowHeight={windowHeight}
          windowWidth={windowWidth}
          isCenter={token ? true : false}
        >
          <S.GridContent container xl={12}>
            {(userSusVideo || scheduling.type_attendance === 'video') && (
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 16, paddingRight: 16 }}
              >
                <S.MessageContainer>
                  <S.MessageText>
                    <b>🚨 Atenção: </b> Durante a videochamada com o paciente, é
                    essencial permanecer na tela para garantir uma comunicação
                    contínua e sem interrupções. Sair da tela pode causar
                    problemas na transmissão de vídeo e áudio.
                  </S.MessageText>
                  <S.MessageText>
                    🚨 <b>Observação: </b> Enquanto a consulta por vídeo estiver
                    acontecendo não será possível finalizar ou cancelar o
                    atendimento!
                  </S.MessageText>
                  <S.MessageText>
                    <b>
                      🌟 Por favor, permaneça na tela enquanto a chamada estiver
                      ativa para oferecer a melhor experiência de atendimento ao
                      seu paciente. 🌟
                    </b>
                  </S.MessageText>
                </S.MessageContainer>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={3}
              style={{
                padding: '2px 6px',
                borderRightWidth: 1,
                borderRightColor: '#f0f2f8',
                borderRightStyle: 'solid',
                order: verifyScrren ? 2 : 1,
              }}
            >
              <Card styles={{ padding: 0 }}>
                {!verifyWidth && (
                  <div style={{ margin: 20 }}>
                    {userSusVideo && (
                      <Alert>
                        <span style={{ fontSize: 20, fontWeight: 'bolder' }}>
                          Atenção: Star Clínics
                        </span>
                        <span>
                          Esse aqui é um(a) paciente Star Clinics, por favor
                          faça o atendimento pela plataforma e encaminhe a
                          prescrição e exames logo após a finalização da
                          consulta.
                        </span>
                      </Alert>
                    )}
                    {userSusWhatsapp && (
                      <Alert>
                        <span>
                          Atendimento via Whatsapp e encaminhe a prescrição e
                          exames logo após a finalização da consulta.
                        </span>
                      </Alert>
                    )}
                  </div>
                )}

                <S.TopContent>
                  <div className="text-content">
                    <span>{translate('medicalRecord.attendance')}</span>
                    <p>
                      {moment
                        .utc(scheduling?.from_date_time)
                        .format('DD/MM/YYYY HH:mm')}
                    </p>
                  </div>
                  <div className="text-content">
                    <span>WhatsApp</span>
                    <p>{telephoneMask(scheduling?.cellphone || '')}</p>
                  </div>
                  <div className="button-content">
                    {timeRemaining !== null && timeRemaining > 0 && (
                      <S.MessageContainer>
                        <S.MessageText>
                          🚨 Por favor, aguarde. O atendimento só poderá ser
                          iniciado 3 minutos antes do horário da consulta, sendo
                          assim você poderá iniciar a consulta em{' '}
                          <b>{formatTime(timeRemaining).duration}</b>.
                        </S.MessageText>
                        <S.MessageText>
                          <b>Tempo restante:</b>{' '}
                          {formatTime(timeRemaining).remaining}
                        </S.MessageText>
                      </S.MessageContainer>
                    )}
                    {scheduling?.status === 'pending' && (
                      <Button
                        color="primary"
                        type="button"
                        size="normal"
                        styles={{ width: '100%', marginBottom: 10 }}
                        onClick={() => setShowInitModal('block')}
                        disabled={isButtonDisabled}
                        title="Iniciar o atendimento"
                      >
                        {translate('medicalRecord.initAttendance')}
                      </Button>
                    )}

                    {scheduling?.status === 'started' && (
                      <>
                        <Button
                          color="primary"
                          type="button"
                          size="normal"
                          styles={{ width: '100%', marginBottom: 10 }}
                          onClick={() => setShowFinishModal('block')}
                          title="Salvar e finalizar o atendimento"
                          disabled={!!token}
                        >
                          {translate('medicalRecord.finishAttendance')}
                        </Button>

                        <Button
                          color="primary-light"
                          type="button"
                          size="normal"
                          styles={{ width: '100%', marginBottom: 40 }}
                          disabled={scheduling?.status === 'pending'}
                          onClick={() =>
                            handlePrescription(
                              scheduling,
                              state,
                              setLoading,
                              setState
                            )
                          }
                          title="Iniciar a prescrição médica"
                        >
                          {translate('medicalRecord.makeMedicalRecord')}
                        </Button>

                        <Button
                          color="success"
                          type="button"
                          size="normal"
                          icon={whatsappIcon}
                          iconStyles={{ width: 20, marginRight: 15 }}
                          styles={{ width: '100%', marginTop: 10 }}
                          onClick={() => handleOpenWhatsApp()}
                          disabled={scheduling?.status === 'pending'}
                          title="Iniciar a video chamada pelo WhatsApp"
                        >
                          {translate('medicalRecord.initChat')}
                        </Button>
                      </>
                    )}

                    {scheduling?.status === 'started' &&
                      (userSusVideo ||
                        scheduling.type_attendance === 'video') && (
                        <Button
                          color="warning"
                          type="button"
                          size="normal"
                          icon={videoIcon}
                          iconStyles={{ width: 18, marginRight: 15 }}
                          styles={{
                            width: '100%',
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                          onClick={() => {
                            handleScrollToTop()
                            sendCallPush(scheduling.id)
                            initVideoCall(
                              scheduling.id,
                              state,
                              setLoading,
                              setState,
                              setToken
                            )
                          }}
                          disabled={scheduling?.status === 'pending' || !!token}
                          title="Iniciar a videochamada interna"
                        >
                          {translate('medicalRecord.initVideo')}
                        </Button>
                      )}

                    {scheduling?.status === 'started' && (
                      <Button
                        color="danger"
                        type="button"
                        size="normal"
                        disabled={scheduling?.status !== 'started' || !!token}
                        styles={{ width: '100%', marginTop: 40 }}
                        onClick={() => setShowCancelModal('block')}
                        title="Cancelar o atendimento"
                      >
                        {translate('medicalRecord.cancelAppointment')}
                      </Button>
                    )}
                  </div>
                </S.TopContent>
                <S.Menu>
                  <ul>
                    {menuItems.map((item: any, key: number) => {
                      if (item.id === 'chat') {
                        return
                      }

                      return (
                        <S.MenuList
                          key={item.id}
                          className={key === selectedMenu ? 'active' : ''}
                        >
                          <button
                            type="button"
                            onClick={() => setSelectedMenu(key)}
                            disabled={
                              (key === 8 &&
                                scheduling?.prescriptions?.filter(
                                  (i: any) => !!i.examPDF
                                ).length === 0) ||
                              (key === 9 &&
                                scheduling?.prescriptions?.filter(
                                  (i: any) => !!i.prescriptionPDF
                                ).length === 0)
                            }
                          >
                            {`${item.title} ${
                              item.id === 'images' &&
                              scheduling?.exams?.length > 0
                                ? `(${scheduling?.exams?.length})`
                                : ''
                            }`}
                          </button>
                        </S.MenuList>
                      )
                    })}
                  </ul>
                </S.Menu>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              style={{
                padding: '2px 6px',
                order: verifyScrren ? 3 : 2,
              }}
            >
              <Card>
                <S.PatientContent>
                  <div className="PatientContent--header">
                    <div>
                      <h3>{normalizeName(scheduling?.patient?.name || '')}</h3>
                      <p>
                        <span>{translate('medicalRecord.age')}</span>
                        {`${getAge(
                          scheduling?.patient?.personal_data?.birthdate || ''
                        )} anos`}
                        <br />
                        <span>{translate('medicalRecord.gender')}</span>
                        {(scheduling?.patient?.personal_data?.gender ===
                          'male' &&
                          translate('medicalRecord.male')) ||
                          ''}
                        {(scheduling?.patient?.personal_data?.gender ===
                          'female' &&
                          translate('medicalRecord.female')) ||
                          ''}
                        {(scheduling?.patient?.personal_data?.gender ===
                          'other' &&
                          translate('medicalRecord.other')) ||
                          ''}
                        <br />
                        <span>{translate('medicalRecord.weight')}</span>
                        {`${
                          scheduling?.patient?.medical_record?.weight || 0
                        } kg`}
                        <br />
                        <span>{translate('medicalRecord.height')}</span>
                        {`${Number(
                          scheduling?.patient?.medical_record?.height / 100
                        ).toFixed(2)} m`}
                        <br />
                        <span>IMC: </span>
                        {`${calcIMC(
                          Number(
                            scheduling?.patient?.medical_record?.height / 100
                          ),
                          scheduling?.patient?.medical_record?.weight
                        )} kg/m2`}
                      </p>
                    </div>
                    {userSusVideo && (
                      <Alert>
                        <span style={{ fontSize: 20, fontWeight: 'bolder' }}>
                          Atenção: Star Clínics
                        </span>
                        <span>
                          Esse aqui é um(a) paciente Star Clinics, por favor
                          faça o atendimento pela plataforma e encaminhe a
                          prescrição e exames logo após a finalização da
                          consulta.
                        </span>
                      </Alert>
                    )}
                    {verifyWidth && userSusWhatsapp && (
                      <Alert>
                        <span>
                          Atendimento via Whatsapp e encaminhe a prescrição e
                          exames logo após a finalização da consulta.
                        </span>
                      </Alert>
                    )}
                  </div>
                  <Global.Line />
                </S.PatientContent>
                {selectedMenu === 0 && (
                  <ChatPatient
                    doctorName={doctorNameShow}
                    scheduleData={scheduling}
                    scheduleUUID={scheduling?.id}
                  />
                )}
                {selectedMenu === 1 && (
                  <Anamnese
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling?.id}
                    scheduleData={scheduling}
                  />
                )}
                {selectedMenu === 2 && (
                  <ComplementarExams
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling?.id}
                  />
                )}
                {selectedMenu === 3 && (
                  <DiagnosticHypothesis
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling?.id}
                  />
                )}
                {selectedMenu === 4 && (
                  <Conduct
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling?.id}
                  />
                )}
                {selectedMenu === 5 && (
                  <ImagesAndAttachments
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling?.id}
                    exams={scheduling?.exams}
                  />
                )}
                {selectedMenu === 6 && (
                  <History
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling?.id}
                    schedule={scheduling}
                  />
                )}
                {selectedMenu === 7 && (
                  <FollowUp
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling.id}
                    scheduleData={scheduling}
                  />
                )}
                {selectedMenu === 8 && (
                  <ExamsAndProcedures
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling?.id}
                    exams={scheduling?.prescriptions?.filter(
                      (item: any) => !!item.examPDF || !!item.attestedPDF
                    )}
                  />
                )}
                {selectedMenu === 9 && (
                  <Prescriptions
                    doctorName={doctorNameShow}
                    scheduleUUID={scheduling?.id}
                    prescriptions={scheduling?.prescriptions?.filter(
                      (item: any) => !!item.prescriptionPDF
                    )}
                  />
                )}
              </Card>
            </Grid>
          </S.GridContent>
        </S.LeftContainer>
        {token && (
          <S.RightContainer>
            <VideoCallSdk
              onCloseRoom={() => setToken('')}
              token={token}
              schedulingId={scheduling?.id}
              windowHeight={windowHeight}
              windowWidth={windowWidth}
            />
          </S.RightContainer>
        )}
      </S.Content>
      {state.showModal && (
        // @ts-ignore
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          open={state.showModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ width: '80%', height: '90vh' }}>
            <iframe
              title={translate('medicalRecord.nexoData')}
              width="100%"
              height="100%"
              src={state.url}
            />
          </div>
        </Modal>
      )}
      <>
        <div className="overlay" style={{ display: showCancelModal }} />
        <div className="modal-wrapper" style={{ display: showCancelModal }}>
          <div className="info-container">
            <ModalShow
              closeModal={hideModal}
              cancelModal
              setDescription={setCancelDescription}
              description={cancelDescription}
              handleConfirm={handleCancelSchedule}
            />
          </div>
        </div>
      </>

      <>
        <div className="overlay" style={{ display: showFinishModal }} />
        <div className="modal-wrapper" style={{ display: showFinishModal }}>
          <div className="info-container">
            <ModalShow
              closeModal={hideFinishModal}
              cancelModal={false}
              handleConfirm={handleSaveMedicalRecord}
              setAccompaniment={setAccompaniment}
              accompaniment={accompaniment}
            />
          </div>
        </div>
        <div className="overlay" style={{ display: showInitModal }} />
        <div className="modal-wrapper" style={{ display: showInitModal }}>
          <div className="info-container">
            <StarService
              closeModal={hideInitModal}
              handleConfirm={handleInitService}
            />
          </div>
        </div>
      </>
    </S.Container>
  )
}

export default NewMedicalRecord

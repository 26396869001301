import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.div`
  img {
    width: 280px;
  }

  @media screen and (min-width: 961px) {
    margin-bottom: 20px;

    img {
      max-width: initial;
    }
  }
`

export const Card = styled.div`
  width: 98%;
  max-width: 450px;
  background: var(--color-white);
  border-radius: 30px;
  padding: 30px 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  h1.card-title {
    font-family: Muli;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    color: var(--color-black);
    margin-bottom: 30px;
  }

  @media screen and (min-width: 961px) {
    padding: 40px 60px;
  }
`

export const AreaButton = styled.div`
  margin-top: 10px;
  display: flex;
`

export const ContentSlect = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;

  > select {
    width: 80px;
    height: 30px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    text-align: center;
    cursor: pointer;
    appearance: none;
  }
`

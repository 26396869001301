import React from 'react'

// Components
import { Button } from '../index'

interface PopupProps {
  title: string
  message: string
  finish?: string
  callbackOne: () => {}
  callbackTwo: () => {}
  callbackFinish?: () => {}
  close: () => void
}

const Popup: React.FunctionComponent<PopupProps> = ({
  title,
  message,
  // finish,
  callbackOne,
  callbackTwo,
  // callbackFinish,
  close,
}) => {
  const executeCallbackOne = () => {
    callbackOne()
    close()
  }

  const executeCallbackTwo = () => {
    callbackTwo()
    close()
  }

  // const executeCallbackFinish = () => {
  //   // @ts-ignore
  //   callbackFinish()
  //   close()
  // }

  return (
    <div className="popup">
      <h1 className="popup__title">{title}</h1>
      <h3 className="popup__subtitle">{message}</h3>
      <div className="popup__row">
        <div className="popup__row__col">
          <Button
            type="button"
            color="primary"
            onClick={() => executeCallbackOne()}
          >
            Sim
          </Button>
        </div>
        <div className="popup__row__col">
          <Button
            type="button"
            color="primary"
            onClick={() => executeCallbackTwo()}
          >
            Não
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Popup

import React from 'react'
import './styles.scss'

interface TextAreaProps {
  autocapitalize?:
    | 'none'
    | 'sentences'
    | 'words'
    | 'characters'
    | 'on'
    | 'off'
    | undefined
  autocomplete?: 'off' | 'on' | undefined
  cols?: number
  disabled?: boolean
  maxlength?: number
  minlength?: number
  name: string
  placeholder?: string
  readonly?: boolean
  required?: boolean
  rows?: number
  spellcheck?: boolean | 'true' | 'false' | undefined
  wrap?: 'hard' | 'soft' | 'off' | undefined
  value?: string
  styles?: any
  onChange?: any
}

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  autocapitalize,
  autocomplete,
  cols,
  disabled,
  maxlength,
  minlength,
  name,
  placeholder,
  readonly,
  required,
  rows,
  spellcheck,
  wrap,
  value,
  styles,
  onChange,
}) => {
  return (
    <div id="textarea-container" className="textarea">
      <textarea
        autoCapitalize={autocapitalize}
        autoComplete={autocomplete}
        cols={cols}
        disabled={disabled}
        maxLength={maxlength}
        minLength={minlength}
        name={name}
        placeholder={placeholder}
        readOnly={readonly}
        required={required}
        rows={rows}
        spellCheck={spellcheck}
        wrap={wrap}
        onChange={onChange}
        style={styles}
      >
        {value}
      </textarea>
    </div>
  )
}

export default TextArea

import { translate } from 'locales'

export const menuItems = [
  {
    id: 'chat',
    title: 'Chat com o paciente',
  },
  {
    id: 'resume',
    title: translate('menuItemsNewAnalysis.resume'),
  },
  {
    id: 'analysis',
    title: translate('menuItemsNewAnalysis.analysis'),
  },
  {
    id: 'anamneseNutri',
    title: translate('menuItemsNewAnalysis.nutritionalHistory'),
  },
  {
    id: 'historic',
    title: translate('menuItemsNewAnalysis.historic'),
  },
  {
    id: 'images',
    title: translate('menuItemsNewAnalysis.images'),
  },
  {
    id: 'terms',
    title: translate('menuItemsNewAnalysis.terms'),
  },
  {
    id: 'nutritionalGuidance',
    title: translate('menuItemsNewAnalysis.nutritionalGuidance'),
  },
  {
    id: 'psychologicalGuidance',
    title: translate('menuItemsNewAnalysis.psychologicalGuidance'),
  },
]

export const defaultNutritional = (name: string, day: string) =>
  `<br>
  <strong>Nome: ${name}</strong></p>
  <p><strong>Data: ${day}</strong><br>
<p><strong>Objetivo:</strong> &nbsp;</p>
<p><br></p>
<p><strong>Suplementação:</strong> &nbsp;</p>
<p><br></p>
<p><strong>Líquidos:</strong>&nbsp;</p>
<p><br></p>
<p><strong>Próxima consulta:</strong> Agendar daqui 30 dias&nbsp; &nbsp;&nbsp;</p>
<p><br></p>
<h2><strong>Opções alimentares&nbsp;</strong></h2>
<p><br></p>
<p><strong>Café da manhã __:__</strong></p>
<ul>
  <li><br></li>
</ul>
<p><br></p>
<p><strong>Lanche da manhã __:__ &nbsp;</strong></p>
<ul>
  <li><br></li>
</ul>
<p><br></p>
<p><strong>Almoço: </strong>&nbsp;</p>
<ul>
  <li><br></li>
</ul>
<p><br></p>
<p><strong>Lanche da tarde:</strong>&nbsp;</p>
<ul>
  <li><br></li>
</ul>
<p><br></p>
<p><strong>Incluso no plano alimentar:&nbsp;</strong></p>
<ul>
  <li><br></li>
</ul>
<p><br></p>
<p><strong>Observar</strong>:&nbsp;</p>`

export const reasonConsultation = [
  translate('nutritionalReason.weigthLoss'),
  translate('nutritionalReason.muscleMassGain'),
  translate('nutritionalReason.weightGain'),
  translate('nutritionalReason.weightMaintenance'),
  translate('nutritionalReason.bingeEating'),
  translate('nutritionalReason.inappetence'),
  translate('nutritionalReason.dietTherapyTreatment'),
  translate('nutritionalReason.qualityOfLife'),
  translate('nutritionalReason.sportPerformance'),
  translate('nutritionalReason.climactericAndMenopause'),
]

export const pathologies = [
  translate('pathologies.hypertension'),
  translate('pathologies.type1diabetes'),
  translate('pathologies.type2diabetes'),
  translate('pathologies.hypothyroidism'),
  translate('pathologies.hyperthyroidism'),
  translate('pathologies.dyslipidemia'),
  translate('pathologies.gastritis'),
  translate('pathologies.reflux'),
  translate('pathologies.migraine'),
  translate('pathologies.candidiasis'),
  translate('pathologies.itu'),
  translate('pathologies.sop'),
  translate('pathologies.liverDiseases'),
  translate('pathologies.kidneyDiseases'),
  translate('pathologies.sii'),
  translate('pathologies.ironDeficiencyAnemia'),
  translate('pathologies.anorexia'),
  translate('pathologies.bulimia'),
  translate('pathologies.autoimmuneDisease'),
  translate('pathologies.none'),
]

export const supplements = [
  translate('nutritionalReason.wheyProtein'),
  translate('nutritionalReason.creatine'),
  translate('nutritionalReason.vitaminD'),
  translate('nutritionalReason.multivitamin'),
  translate('nutritionalReason.melatonin'),
  translate('nutritionalReason.none'),
  translate('nutritionalReason.others'),
]

export const waterConsumption = [
  '0 à 500 ml',
  '500 ml à 1L',
  '1L à 2L',
  '2L à 3L',
  '3L ou mais',
]

export const intestinalHabit = [
  translate('nutritionalReason.normal'),
  translate('nutritionalReason.pasty'),
  translate('nutritionalReason.liquids'),
  translate('nutritionalReason.capric'),
]

export const fraquency = [
  translate('nutritionalReason.1xWeek'),
  translate('nutritionalReason.2xWeek'),
  translate('nutritionalReason.3xWeek'),
  translate('nutritionalReason.2xDay'),
  translate('nutritionalReason.alternateDay'),
]

export const sleep = [
  translate('nutritionalReason.insomnia'),
  translate('nutritionalReason.nocturnalAwakenings'),
  translate('nutritionalReason.wakeUpRested'),
  translate('nutritionalReason.wakeUpTired'),
]

export const youAre = [
  translate('nutritionalReason.vegan'),
  translate('nutritionalReason.vegetarian'),
  translate('nutritionalReason.ovolacto'),
  translate('nutritionalReason.omnivorous'),
]

import React, { useState, useEffect, useRef } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { Modal } from '@material-ui/core'
import VirtualBackgroundExtension from 'agora-extension-virtual-background'
import agoraClient from '../../configs/agoraClient'
import * as S from './styles'
import useVideocall from 'hooks/videocall'

import image1 from '../../assets/backgrounds/image1.jpg'
import image2 from '../../assets/backgrounds/image2.jpg'
import image3 from '../../assets/backgrounds/image3.jpg'
import image4 from '../../assets/backgrounds/image4.jpg'
import disableIcon from '../../assets/backgrounds/disable.svg'
import unfocusedIcon from '../../assets/backgrounds/unfocused.svg'

interface SdkVideoProps {
  onCloseRoom: () => void
  token: string
  schedulingId: string
  windowHeight: number
  windowWidth: number
}

const SdkVideo: React.FC<SdkVideoProps> = ({
  onCloseRoom,
  token,
  schedulingId,
  windowHeight,
  windowWidth,
}) => {
  const {
    localParameters,
    selectedBg,
    isFullScreen,
    isLocalMain,
    localPaused,
    initAgora,
    join,
    remotePaused,
    remoteDisconnected,
    leaveRoom,
    getDevices,
    selectedCamera,
    selectedMicrophone,
    toggleAudio,
    toggleFullScreen,
    toggleVideo,
    setBackgroundBlurring,
    setBackgroundImage,
    setBackgroundNone,
    handleChangeCamera,
    handleChangeMicrophone,
    handleConfigClick,
    handleDeviceConfigClick,
    handleVideoClick,
    devices,
    showConfig,
    showDeviceConfig,
    setShowConfig,
    setShowDeviceConfig,
    buttonsDisabled,
    setButtonsDisabled,
  } = useVideocall()

  const settingsButtonRef = useRef<HTMLButtonElement>(null)

  const extension = new VirtualBackgroundExtension()
  AgoraRTC.registerExtensions([extension])

  useEffect(() => {
    if (!extension.checkCompatibility()) {
      console.error('Does not support Virtual Background!')
    }
  }, [extension])

  useEffect(() => {
    initAgora()

    return () => {
      agoraClient.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    join(schedulingId, token)
  }, [schedulingId, token])

  useEffect(() => {
    getDevices()
  }, [])

  const leave = async () => {
    leaveRoom()
    onCloseRoom()
  }

  console.log(windowHeight, 'HEIGHT')

  return (
    <S.Container
      isFullScreen={isFullScreen}
      showConfig={showConfig}
      showDeviceConfig={showDeviceConfig}
      className={isFullScreen ? '' : 'scroll'}
      windowHeight={windowHeight}
      windowWidth={windowWidth}
    >
      {token && (
        <>
          <S.MainVideo
            isFullScreen={isFullScreen}
            showConfig={showConfig}
            showDeviceConfig={showDeviceConfig}
            windowHeight={windowHeight}
          >
            <S.VideoContainer isFullScreen={isFullScreen}>
              <S.RemoteVideo
                id="remote"
                isMain={!isLocalMain}
                isFullScreen={isFullScreen}
                paused={remotePaused}
                disconnected={remoteDisconnected}
                onClick={handleVideoClick}
              >
                {remotePaused && <p>Video pausado</p>}
              </S.RemoteVideo>
              <S.LocalVideo
                id="local"
                isMain={isLocalMain}
                isFullScreen={isFullScreen}
                paused={localPaused}
                onClick={handleVideoClick}
              />
            </S.VideoContainer>
          </S.MainVideo>
          <S.MenuWrapper isFullScreen={isFullScreen}>
            <S.Footer isFullScreen={isFullScreen}>
              <S.UtilityButtons>
                <S.ToggleButton
                  onClick={toggleAudio}
                  active={localParameters.audioMuted}
                  title={
                    localParameters.audioMuted
                      ? 'Ativar Áudio'
                      : 'Desativar Áudio'
                  }
                  disabled={buttonsDisabled}
                >
                  <img
                    src={
                      localParameters.audioMuted
                        ? '/icons/microphone-off.svg'
                        : '/icons/microphone.svg'
                    }
                    alt="Mic Icon"
                  />
                </S.ToggleButton>
                <S.ToggleButton
                  onClick={toggleVideo}
                  active={!localParameters.videoEnabled}
                  title={
                    localParameters.videoEnabled
                      ? 'Parar Vídeo'
                      : 'Iniciar Vídeo'
                  }
                  disabled={buttonsDisabled}
                >
                  <img
                    src={
                      localParameters.videoEnabled
                        ? '/icons/video.svg'
                        : '/icons/video-off.svg'
                    }
                    alt="Video Icon"
                  />
                </S.ToggleButton>
                <S.SettingsButton
                  ref={settingsButtonRef}
                  onClick={handleConfigClick}
                  active={showConfig}
                  title="Efeitos"
                  disabled={buttonsDisabled}
                >
                  <img src="/icons/effects.svg" alt="Settings Icon" />
                </S.SettingsButton>
                <S.SettingsButton
                  ref={settingsButtonRef}
                  onClick={handleDeviceConfigClick}
                  active={showDeviceConfig}
                  title="Dispositivos"
                  disabled={buttonsDisabled}
                >
                  <img src="/icons/ic-settings.svg" alt="Devices Icon" />
                </S.SettingsButton>
                <S.FullScreenButton
                  onClick={toggleFullScreen}
                  title={isFullScreen ? 'Sair da Tela Cheia' : 'Tela Cheia'}
                  active={isFullScreen}
                  disabled={buttonsDisabled}
                >
                  <img
                    src={
                      isFullScreen
                        ? '/icons/exit-screen.svg'
                        : '/icons/full-screen.svg'
                    }
                    alt="FullScreen Icon"
                  />
                </S.FullScreenButton>
                <S.EndButton
                  id="video_call_end"
                  onClick={leave}
                  title="Encerrar Chamada"
                  disabled={buttonsDisabled}
                >
                  <img src="/icons/end-call.svg" alt="Phone Icon" />
                </S.EndButton>
              </S.UtilityButtons>
            </S.Footer>
          </S.MenuWrapper>
        </>
      )}
      {/* @ts-ignore */}
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={showConfig}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <S.ConfigWrapper isFullScreen={isFullScreen}>
          <S.ConfigButtonWrapper isFullScreen={isFullScreen}>
            <S.ConfigButton
              onClick={setBackgroundNone}
              selected={selectedBg === 'none'}
              title="Desativar Fundo"
            >
              <img
                src={disableIcon}
                alt="Desativar Fundo"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                }}
              />
            </S.ConfigButton>
            <S.ConfigButton
              onClick={setBackgroundBlurring}
              selected={selectedBg === 'blur'}
              title="Ativar Desfoque"
            >
              <img
                src={unfocusedIcon}
                alt="Ativar Desfoque"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                }}
              />
            </S.ConfigButton>
            <S.ConfigButton
              onClick={() => setBackgroundImage(image1)}
              selected={selectedBg === image1}
              title="Plano de fundo 1"
            >
              <img
                src={image1}
                alt="Plano de fundo 1"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                }}
              />
            </S.ConfigButton>
            <S.ConfigButton
              onClick={() => setBackgroundImage(image2)}
              selected={selectedBg === image2}
              title="Plano de fundo 2"
            >
              <img
                src={image2}
                alt="Plano de fundo 2"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                }}
              />
            </S.ConfigButton>
            <S.ConfigButton
              onClick={() => setBackgroundImage(image3)}
              selected={selectedBg === image3}
              title="Plano de fundo 3"
            >
              <img
                src={image3}
                alt="Plano de fundo 3"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                }}
              />
            </S.ConfigButton>
            <S.ConfigButton
              onClick={() => setBackgroundImage(image4)}
              selected={selectedBg === image4}
              title="Plano de fundo 4"
            >
              <img
                src={image4}
                alt="Plano de fundo 4"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                }}
              />
            </S.ConfigButton>
          </S.ConfigButtonWrapper>
          <S.SaveButton
            isFullScreen={isFullScreen}
            onClick={() => {
              setShowConfig(false)
              setButtonsDisabled(false)
            }}
          >
            Salvar
          </S.SaveButton>
        </S.ConfigWrapper>
      </Modal>

      {/* @ts-ignore */}
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={showDeviceConfig}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <S.ConfigWrapper isFullScreen={isFullScreen}>
          <S.DeviceSelectWrapper isFullScreen={isFullScreen}>
            <S.Label>Câmera:</S.Label>
            <S.Select
              value={selectedCamera}
              onChange={(e) => handleChangeCamera(e.target.value)}
            >
              {devices.cameras.map((camera) => (
                <option key={camera.deviceId} value={camera.deviceId}>
                  {camera.label}
                </option>
              ))}
            </S.Select>
            <S.Label>Microfone:</S.Label>
            <S.Select
              value={selectedMicrophone}
              onChange={(e) => handleChangeMicrophone(e.target.value)}
            >
              {devices.microphones.map((microphone) => (
                <option key={microphone.deviceId} value={microphone.deviceId}>
                  {microphone.label}
                </option>
              ))}
            </S.Select>
          </S.DeviceSelectWrapper>
          <S.SaveButton
            isFullScreen={isFullScreen}
            onClick={() => {
              setShowDeviceConfig(false)
              setButtonsDisabled(false)
            }}
          >
            Salvar
          </S.SaveButton>
        </S.ConfigWrapper>
      </Modal>
    </S.Container>
  )
}

export default SdkVideo

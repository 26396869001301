/* eslint-disable import/no-duplicates */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import pdfIcon from 'assets/icons/pdf.svg'
import { Button } from 'components'
import { translate } from 'locales'
import { parseISO, differenceInMinutes } from 'date-fns'
import CardAnamneseNutri from 'components/CardAnamneseNutri'

// Styles
import { Title, CardNutritionalGuidance } from 'components'
import * as N from '../New/styles'
// Interfaces
import { IComponentProps } from './interfaces'

const TimeLineResume: React.FunctionComponent<IComponentProps> = ({
  record,
}) => {
  const notInformad = 'Não informado'

  const doctorTag = record?.scheduling?.tags_doctor || []
  const patientTag = record?.scheduling?.tags_patient || []

  const startConsultation = parseISO(record?.scheduling?.from_date_time)
  const endConsultation = parseISO(record?.scheduling?.to_date_time)

  const consultationDuration = differenceInMinutes(
    endConsultation,
    startConsultation
  )

  return (
    <N.SectionContent style={{ padding: 10 }}>
      <div className="subsection">
        <div className="subsection-item">
          <Title text={translate('medicalRecord.reason')} />
          <p>{record?.scheduling?.symptoms || notInformad}</p>
        </div>
        <div className="subsection-item">
          <Title text={translate('medicalRecord.consultationTime')} />
          <p>{consultationDuration} min</p>
        </div>
        {record?.scheduling?.specialty_name === 'Psicólogo' ? (
          <>
            <Title text={translate('medicalRecord.patientComplaint')} />
            <div className="tag-container">
              {patientTag?.length > 0 ? (
                <>
                  {patientTag.map((tag: any) => (
                    <Button
                      className="tag-button"
                      key={tag.id}
                      color="tag-active"
                      type="button"
                    >
                      {tag?.title || notInformad}
                    </Button>
                  ))}
                </>
              ) : (
                <p>{notInformad}</p>
              )}
            </div>
            <Title text={translate('medicalRecord.perceivedDemand')} />
            <div className="tag-container">
              {doctorTag?.length > 0 ? (
                <>
                  {doctorTag.map((tag: any) => (
                    <Button
                      className="tag-button"
                      key={tag.id}
                      color="tag-active"
                      type="button"
                    >
                      {tag?.title}
                    </Button>
                  ))}
                </>
              ) : (
                <p>{notInformad}</p>
              )}
            </div>
          </>
        ) : null}

        <Title text={translate('medicalRecord.anotations')} />
        <div className="subsection-item">
          <p>{record?.annotation || notInformad}</p>
        </div>
      </div>
      {record?.specialty_name !== 'Psicólogo' && (
        <>
          <Title text={translate('menuItemsNew.anamnese')} />
          <div className="subsection-item">
            <CardAnamneseNutri
              record={record?.scheduling?.nutritional_anamnesis}
            />
          </div>

          <div className="subsection">
            <Title text={translate('medicalRecord.nutritionalGuidance')} />
            <div className="subsection-item">
              {record?.scheduling?.nutritionalGuidance?.attachment ? (
                <CardNutritionalGuidance
                  patientId={record.patient_id}
                  scheduleUUID={record?.scheduling?.id}
                  nutritional={
                    record?.scheduling?.nutritionalGuidance?.attachment
                  }
                />
              ) : (
                <p> {notInformad} </p>
              )}
            </div>

            <Title text={translate('medicalRecord.images')} />
            <div className="subsection-item">
              {record.exams && record.exams.length ? (
                <section className="section-resume">
                  <div className="content-resume">
                    <N.AttachmentsList>
                      {record.exams &&
                        record.exams.map((exam: any, index: number) => {
                          const typeImage = 'image'
                          const isImage = exam.type.includes(typeImage)

                          return isImage ? (
                            <button
                              type="button"
                              className="images"
                              onClick={() =>
                                window.open(exam.location, '_blank')
                              }
                            >
                              <img
                                src={exam.location}
                                alt="Imagem de um exame"
                              />
                              <span>
                                {`${translate('medicalRecord.file')} ${
                                  index + 1
                                }`}
                              </span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="files"
                              onClick={() =>
                                window.open(exam.location, '_blank')
                              }
                            >
                              <img
                                src={pdfIcon}
                                alt="Icone que representa um arquivo PDF"
                              />
                              <span>
                                {`${translate('medicalRecord.file')} ${
                                  index + 1
                                }`}
                              </span>
                            </button>
                          )
                        })}
                    </N.AttachmentsList>
                  </div>
                </section>
              ) : (
                <p>Nenhum Anexo</p>
              )}
            </div>
          </div>
        </>
      )}
    </N.SectionContent>
  )
}

export default TimeLineResume

/* eslint-disable react/jsx-indent */
import React from 'react'

import { CardNutritionalGuidance, CardRecipe } from 'components'

import CardAnamneseNutri from 'components/CardAnamneseNutri'

import { translate } from 'locales'

import pdfIcon from 'assets/icons/pdf.svg'

import * as S from './styles'
import * as N from '../New/styles'

const defaultMessage = translate('medicalRecord.uninformed')

const TimeLineResumeNutritionist = ({ history, setLoading }: any) => {
  return (
    <S.TimeResumeContent>
      <section className="section-resume">
        <div className="title-resume">
          {translate('medicalRecord.anotations')}
        </div>
        <div className="content-resume">
          <S.Item>
            <strong>{translate('medicalRecord.reason')}</strong>
            <p>{history?.scheduling?.symptoms || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{`${translate('medicalRecord.anotations')}:`}</strong>
            <p>{history?.annotation || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{`${translate('menuItemsNew.anamnese')}:`}</strong>
            <CardAnamneseNutri
              record={history?.scheduling?.nutritional_anamnesis}
            />
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.nutritionalGuidance')}</strong>
            <CardNutritionalGuidance
              patientId={history.patient_id}
              scheduleUUID={history?.scheduling?.id}
              nutritional={history?.scheduling?.nutritionalGuidance?.attachment}
              setLoading={setLoading}
            />
          </S.Item>
          <S.Item>
            <strong>{translate('medicalRecord.recipe')}</strong>
            <CardRecipe
              patientId={history?.patient_id}
              scheduleUUID={history?.scheduling?.id}
              recipe={history?.scheduling?.nutritionalGuidance?.recipe}
              setLoading={setLoading}
            />
          </S.Item>
        </div>
      </section>
      {history?.scheduling?.exams && history?.scheduling?.exams.length ? (
        <section className="section-resume">
          <div className="title-resume">
            {translate('medicalRecord.images')}
          </div>
          <div className="content-resume">
            <N.AttachmentsList>
              {history?.scheduling?.exams &&
                history?.scheduling?.exams.map((exam: any, index: number) => {
                  const isImage = exam.type

                  return isImage !== '.pdf' ? (
                    <button
                      type="button"
                      className="images"
                      onClick={() => window.open(exam.location, '_blank')}
                    >
                      <img src={exam.location} alt="Imagem de um exame" />
                      <span>
                        {`${translate('medicalRecord.file')} ${index + 1}`}
                      </span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="files"
                      onClick={() => window.open(exam.location, '_blank')}
                    >
                      <img
                        src={pdfIcon}
                        alt="Icone que representa um arquivo PDF"
                      />
                      <span>
                        {`${translate('medicalRecord.file')} ${index + 1}`}
                      </span>
                    </button>
                  )
                })}
            </N.AttachmentsList>
          </div>
        </section>
      ) : null}
    </S.TimeResumeContent>
  )
}

export default TimeLineResumeNutritionist

import React from 'react'
import api from 'configs/api'
import moment from 'moment'
import { alertDanger } from 'utils/toast'
import { translate } from 'locales'

export const savePrescription = async (data: any) => {
  try {
    await api.post('/doctor/v1/prescription', {
      ...data,
      userUUID: data.Paciente.Endereco.Endereco2,
      scheduleUUID: '',
    })
  } catch (err) {
    //
  }
}

export const findAllMedicalRecords = async (
  userUUID: string,
  setRecord: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  page: number,
  setTotalPages: any
) => {
  setLoading(true)
  try {
    const find = await api.get(
      `/doctors/v2/medical-record/user/${userUUID}?page=${page}&limit=6`
    )
    const { medicalRecords, patient, totalPages } = find.data
    setTotalPages(totalPages)

    const result = medicalRecords.reduce((res: any, i: any) => {
      res[moment.utc(i.created_at).format('YYYY/MM/DD')] =
        res[moment.utc(i.created_at).format('YYYY/MM/DD')] || []

      res[moment.utc(i.created_at).format('YYYY/MM/DD')].push(i)

      return res
    }, {})

    setRecord({
      user: patient,
      address: patient?.address,
      cellphones: [patient?.personal_data?.cellphone],
      medicalRecords: result,
    })
    setLoading(false)
  } catch (err: any) {
    setLoading(false)
    alertDanger(
      err?.response?.data?.data?.errors[0]?.msg ||
        translate('errors.internalError')
    )
  }
}

export const findAllMedicalRecordsPsico = async (
  userUUID: string,
  areaUUID: any,
  setRecord: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  page?: number,
  setTotalPages?: any
) => {
  try {
    setLoading(true)
    const find = await api.get(
      `/doctors/v2/medical-record/user/${userUUID}/psico/${areaUUID}?page=${page}&limit=10`
    )

    const { patient, medicalRecords, totalPages } = find.data
    setTotalPages(totalPages)
    const resultPsico = medicalRecords.reduce((res: any, i: any) => {
      res[moment.utc(i.created_at).format('YYYY/MM/DD')] =
        res[moment.utc(i.created_at).format('YYYY/MM/DD')] || []

      res[moment.utc(i.created_at).format('YYYY/MM/DD')].push(i)

      return res
    }, {})

    setRecord({
      user: patient,
      medicalRecordsPsico: resultPsico,
    })
    setLoading(false)
  } catch (err: any) {
    setLoading(false)
    alertDanger(
      err?.response?.data?.data?.errors[0]?.msg ||
        translate('errors.internalError')
    )
  }
}
export const findAllMedicalRecordsFollowUp = async (
  userUUID: string,
  setRecord: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const find = await api.get(`nursing/accompaniment/doctor/user/${userUUID}`)

    const datas = find.data

    const resultFollowUp = datas.reduce((res: any, i: any) => {
      res[moment.utc(i.date_accompaniment).format('YYYY/MM/DD')] =
        res[moment.utc(i.date_accompaniment).format('YYYY/MM/DD')] || []

      res[moment.utc(i.date_accompaniment).format('YYYY/MM/DD')].push(i)

      return res
    }, {})

    setRecord({
      medicalRecordsFollowUp: resultFollowUp,
    })
    setLoading(false)
  } catch (err: any) {
    setLoading(false)
    alertDanger(
      err?.response?.data?.data?.errors[0]?.msg ||
        translate('errors.internalError')
    )
  }
}

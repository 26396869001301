import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ITheme } from 'theme/interface'
import logo from '../../../assets/images/logo.svg'

/* eslint-disable-next-line */
interface IMenuList {
  className?: string
  theme: ITheme
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`

export const LeftContainer = styled.div<{
  windowHeight: number
  windowWidth: number
  isCenter?: boolean
}>`
  width: ${({ windowWidth, isCenter }) =>
    !isCenter ? '1200px' : `${windowWidth - 300}px`};
  margin: 0 auto;
  height: ${({ windowHeight }) => windowHeight}px;
  display: flex;
  overflow-y: scroll;

  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }
`

export const RightContainer = styled.div`
  width: 300px;
  display: flex;
  padding-top: 80px;

  @media (max-width: 480px) {
    width: 100%;
  }
`

export const GridContent = styled(Grid)<{
  noMaxWidth?: boolean
  windowHeight?: number
  resize?: boolean
}>`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 90px;
  overflow-y: scroll;
`

export const TopContent = styled.div`
  width: 100%;
  padding: 20px;

  .text-content {
    p,
    span {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: ${({ theme }) => theme.colors.dark} !important;
    }

    p {
      font-weight: normal !important;
    }
  }

  .button-content {
    width: 100%;
    margin-top: 20px;
  }
`

export const Menu = styled.nav`
  margin-top: 20px;

  ul {
    width: 100%;

    li {
      width: 100% !important;
      list-style: none;

      button {
        width: 100%;
        background-color: transparent;
        height: 40px;
        border: none;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.secondary};
        padding-left: 20px;
        outline: none !important;

        :disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
    }
  }
`

/* eslint-disable-next-line */
export const MenuList = styled.li<IMenuList>`
  width: 100%;
  background-color: ${({ className, theme }) =>
    className === 'active' ? theme.colors.background : 'transparent'};
  border-left: ${({ className, theme }) =>
    className === 'active'
      ? `2px solid ${theme.colors.secondary}`
      : 'none'} !important;

  border-top: 1px solid
    ${({ className, theme }) =>
      className === 'active' ? 'transparent' : theme.colors.gray10} !important;
  height: 40px;
  border: none;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
`

export const PatientContent = styled.div`
  h3 {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.colors.dark};
  }

  p {
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: normal !important;
    color: ${({ theme }) => theme.colors.gray80} !important;
    margin-top: 15px;

    span {
      font-weight: 600 !important;
    }
  }
`

export const SectionContent = styled.section`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: ${({ theme }) => theme.radius.default}px;
  padding: 30px;

  span {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }

  p {
    font-size: 14px !important;
    line-height: 18px !important;
    color: ${({ theme }) => theme.colors.gray50} !important;
  }

  .subsection {
    .subsection-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      span {
        font-size: 14px !important;
        color: ${({ theme }) => theme.colors.gray80} !important;
      }

      p {
        font-size: 16px !important;
        color: ${({ theme }) => theme.colors.dark} !important;
        font-weight: 600 !important;
        line-height: 22px !important;
      }

      .vital-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .vital-content-left {
          width: 80%;
        }

        .vital-content-right {
          display: flex;
          flex: 1;
          justify-content: flex-start;
          padding-left: 10px;
        }
      }
    }

    .cards-prescriptions {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`

export const SectionNutri = styled.section`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: ${({ theme }) => theme.radius.default}px;
  padding: 30px;

  > span {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }

  > p {
    font-size: 14px !important;
    line-height: 18px !important;
    color: ${({ theme }) => theme.colors.gray50} !important;
  }

  .text {
    strong {
      font-size: 26px !important;
      font-weight: 600 !important;
    }
  }
`

export const AttachmentsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;

  .files {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    padding-top: 30px;

    img {
      width: 56px;
      height: 75.6px;
      margin-bottom: 10px;
    }

    span {
      max-width: 100px;
    }
  }

  .images {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    border: none;
    outline: none;
    background-color: transparent;

    img {
      width: 100%;
      margin-bottom: 10px;
    }

    span {
      max-width: 200px;
    }
  }
`

export const NoData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 200px;
  }

  span {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.gray80};
    margin-top: 30px;
  }
`

export const CardPrescriptionExams = styled.div`
  width: 100%;
  min-height: 100px;
  margin-bottom: 20px;
  padding: 14px;
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
  }

  span {
    color: ${({ theme }) => theme.colors.gray80};
    margin-bottom: 10px;
  }

  @media screen and (min-width: 961px) {
    width: calc(100% / 3 - 10px);
    margin-right: 5px;
    margin-left: 5px;
  }
`

export const AnalysisWrapper = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.gray10};

  &:after {
    content: '';
    width: 42rem;
    height: 15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${logo});
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
    opacity: 0.1;
  }

  .MuiInput-underline {
    padding: 4rem;
    z-index: 2;

    &:after,
    &:before {
      display: none !important;
    }

    &:hover {
      &:after,
      &:before {
        display: none !important;
      }
    }
  }

  .analysisHeader {
    padding: 2rem;

    img {
      max-width: 16rem;
    }
  }

  .analysisFooter {
    background-color: ${({ theme }) => theme.colors.secondary};
    padding: 0.5rem;
    text-align: center;

    a {
      color: #fff;
      font-size: 1.5rem;
    }
  }
`

export const CountCharacters = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.gray80};
`

export const Div = styled.div`
  margin-bottom: 10px;
`

export const TermContent = styled.section`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: ${({ theme }) => theme.radius.default}px;
  padding: 30px;

  span {
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }

  .subsection {
    .subsection-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      p {
        font-size: 16px !important;
        color: ${({ theme }) => theme.colors.dark} !important;
        line-height: 22px !important;
      }
    }
  }
`

export const ContentExam = styled.div`
  width: 100%;
  border: 1px #ccc dashed;
  border-radius: 8px;
  padding: 15px;
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > img {
    height: 100px;
  }
`

export const ContentPagination = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: flex-end;
  flex-direction: column;
`

export const MessageContainer = styled.div`
  width: 100%;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.secondaryLight};
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 15px 30px;
`

export const MessageText = styled.p`
  font-size: 16px;
`

import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import {
  IconButton,
  InputAdornment,
  TextField as Input,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { translate } from 'locales'

// Configs
import api from 'configs/api'

// Utils
import { alertDanger, alertSuccess } from 'utils/toast'

// Contexts
import { useSignIn } from 'context/authentication/signin.context'
import { useUser } from 'context/user/user.context'

// Components
import { Button, Loading } from 'components'

// Images
import logo from 'assets/images/logo.svg'
import * as S from './styles'
import { schemaLogin } from './validations'

const Login = () => {
  const history = useHistory()
  const { signInSuccess, verifySignIn } = useSignIn()
  const { setUserData } = useUser()

  // State
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  // const [code, setCode] = useState('')
  // const [userId, setUserId] = useState('')
  // const [verify, setVerify] = useState(false)
  // const [resend, setResend] = useState(false)

  useEffect(() => {
    if (verifySignIn()) {
      history.push('/dashboard')
    }
  }, [history, verifySignIn])

  const initialValues = useMemo(() => {
    return {
      email: '',
      password: '',
    }
  }, [])

  const changeSelect = (item: string) => {
    window.localStorage.setItem('@StarBem:lang', item)
    window.location.reload()
  }

  // const validateCode = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await api.post('doctors/v1/users/login/verify', {
  //       user_id: userId,
  //       code,
  //     })
  //     const { doctor, token } = response.data
  //     window.localStorage.setItem('@StarBem:user', JSON.stringify(doctor))
  //     setUserData(doctor)
  //     signInSuccess(token)
  //     history.push('/dashboard')
  //     setLoading(false)
  //     alertSuccess(`${translate('login.welcome')}, ${doctor?.name}!`)
  //   } catch (err: any) {
  //     setLoading(false)
  //     alertDanger(translate('login.codeInvalid'))
  //   }
  // }

  const formik = useFormik({
    initialValues,
    validationSchema: schemaLogin,
    onSubmit: async (values: any) => {
      setLoading(true)

      // if (!verify) {
      try {
        const response = await api.post('doctors/v1/users/login', {
          email: values.email,
          password: values.password,
        })

        // setUserId(response.data.doctor_id)
        // setVerify(true)
        const { doctor, token } = response.data
        window.localStorage.setItem('@StarBem:user', JSON.stringify(doctor))

        setUserData(doctor)
        signInSuccess(token)
        history.push('/dashboard')
        setLoading(false)
        alertSuccess(`${translate('login.welcome')}, ${doctor?.name}!`)
      } catch (err: any) {
        const { data } = err?.response

        alertDanger(
          data?.message || 'Ocorreu um erro inesperado, tente novamente!'
        )
        setLoading(false)
      }
      // } else {
      //   validateCode()
      // }
    },
  })

  // const resendCode = async () => {
  //   setResend(true)

  //   try {
  //     await api.post('doctors/v1/users/login/token/resend', {
  //       user_id: userId,
  //     })

  //     alertSuccess(`${translate('login.codeSend')}!`)
  //     const myTimeout = setTimeout(() => {
  //       setResend(false)
  //       clearTimeout(myTimeout)
  //     }, 60000)
  //   } catch (err: any) {
  //     alertDanger('Erro ao enviar código')
  //   }
  // }

  return (
    <S.Container>
      <S.ContentSlect>
        <select
          name="locales"
          id="locales"
          value={
            window.localStorage.getItem('@StarBem:lang') === 'en' ? 'en' : 'pt'
          }
          onChange={(e) => changeSelect(e.target.value)}
        >
          <option value="pt">🇧🇷 PT</option>
          <option value="en">🇺🇸 EN</option>
        </select>
      </S.ContentSlect>
      {loading && <Loading title={translate('login.loading')} />}
      <S.Logo>
        <img
          src={logo}
          alt="Logo em imagem que representa a marca da Starbem"
        />
      </S.Logo>
      {/* {!verify ? ( */}
      <S.Card>
        <form onSubmit={formik.handleSubmit}>
          <Input
            id="outlined-basic"
            label="Email"
            type="email"
            name="email"
            variant="outlined"
            className="input-default"
            color="secondary"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
          />
          <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
            <InputLabel htmlFor="outlined-adornment-password" color="secondary">
              {translate('login.password')}
            </InputLabel>
            <OutlinedInput
              label={translate('login.password')}
              id="outlined-basic"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              name="password"
              onChange={formik.handleChange}
              color="secondary"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={() => {
                      setShowPassword(!showPassword)
                    }}
                    edge="end"
                    style={{ outline: 'none' }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <S.AreaButton>
            <Button
              color="primary"
              type="submit"
              disabled={!formik.isValid}
              styles={{ width: '100%', height: 50 }}
            >
              Entrar
            </Button>
          </S.AreaButton>
        </form>
      </S.Card>
      {/* ) : (
        <S.Card>
          <div
            className="container"
            style={{ alignContent: 'center', marginTop: '30px' }}
          >
            <h2 className="card-title">{translate('login.authentication')}</h2>
            <span>{translate('login.infoCode')}</span>

            <form onSubmit={formik.handleSubmit}>
              <Input
                id="outlined-basic"
                label={translate('login.code')}
                placeholder="Informe o código"
                type="text"
                name="codigo"
                variant="outlined"
                className="input-default"
                color="secondary"
                onChange={(e) => setCode(e.target.value)}
                value={code}
              />
              <Button
                color="link-danger"
                type="reset"
                size="small"
                styles={{ width: '100%' }}
                onClick={() => resendCode()}
                disabled={resend}
              >
                {translate('login.resendCode')}
              </Button>
              <S.AreaButton>
                <Button
                  color="primary"
                  type="submit"
                  styles={{ width: '100%' }}
                >
                  {translate('login.send')}
                </Button>
              </S.AreaButton>
            </form>
          </div>
        </S.Card>
      )} */}
    </S.Container>
  )
}

export default Login

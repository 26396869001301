import React from 'react'
import './styles.scss'

// Images
import searchIcon from '../../assets/icons/search.svg'

interface SearchProps {
  placeholder?: string | undefined
  value: string | number | undefined
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  name: string
}

const Search: React.FunctionComponent<SearchProps> = ({
  placeholder,
  value,
  onChange,
  name,
}) => {
  return (
    <div className="search-container">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
      />
      <img src={searchIcon} alt="Pesquisar" />
    </div>
  )
}

export default Search

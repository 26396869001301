import React from 'react'
import moment from 'moment'

import { Button } from 'components'
import pdfIcon from 'assets/icons/pdf.svg'

import { translate } from 'locales'

import * as S from './styles'
import * as N from '../New/styles'

const defaultMessage = translate('medicalRecord.uninformed')

const TimeLineResume = ({ history, prescriptions, exams }: any) => {
  return (
    <S.TimeResumeContent>
      <section className="section-resume">
        <div className="title-resume">Anamnese</div>
        <div className="content-resume">
          <S.Item>
            <strong>{translate('medicalRecord.reason')}</strong>
            <p>{history?.scheduling?.symptoms || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.compaint')}</strong>
            <p>{history?.complaint || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.history')}</strong>
            <p>{history?.history || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.allergies')}</strong>
            <p>{history?.allergies || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.diabetes')}</strong>
            <p>{history?.diabetes === 'sim' ? 'Sim' : 'Não'}</p>
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.pathologicalBackground')}</strong>
            <p>{history?.pathological_background || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.continuousRemedy')}</strong>
            <p>{history?.continuous_remedy || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.vitalSigns')}</strong>
            <p>
              <strong>{`${translate('medicalRecord.heartRate')}: `}</strong>
              {history?.heart_rate || defaultMessage}
              <br />
              <strong>{`${translate('medicalRecord.coSaturation')}: `}</strong>
              {history?.co2_saturation || history?.co2_saturation === '0'
                ? history?.co2_saturation
                : defaultMessage}
              <br />
              <strong>
                {`${translate('medicalRecord.systolicBloodPressure')}: `}
              </strong>
              {history?.systolic_blood_pressure || defaultMessage}
              <br />
              <strong>
                {`${translate('medicalRecord.diastolicBloodPressure')}: `}
              </strong>
              {history?.diastolic_blood_pressure || defaultMessage}
              <br />
              <strong>
                {`${translate('medicalRecord.respiratoryFrequency')}: `}
              </strong>
              {history?.respiratory_frequency || defaultMessage}
              <br />
            </p>
          </S.Item>
        </div>
      </section>

      <section className="section-resume">
        <div className="title-resume">
          {translate('medicalRecord.complementarExams')}
        </div>
        <div className="content-resume">
          <S.Item>
            <p>{history?.complementarExams || defaultMessage}</p>
          </S.Item>
        </div>
      </section>

      <section className="section-resume">
        <div className="title-resume">
          {translate('medicalRecord.diagnosticHypothesis')}
        </div>
        <div className="content-resume">
          <S.Item>
            <strong>{`${translate('medicalRecord.diagnostic')}:`}</strong>
            <p>{history?.hypothesis || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>
              {`${translate('medicalRecord.clinicalImpression')}:`}
            </strong>
            <p>{history?.clinical_impression || defaultMessage}</p>
          </S.Item>
        </div>
      </section>

      <section className="section-resume">
        <div className="title-resume">{translate('medicalRecord.conduct')}</div>
        <div className="content-resume">
          <S.Item>
            <p>{history?.conduct || defaultMessage}</p>
          </S.Item>
        </div>
      </section>

      {prescriptions &&
      prescriptions.filter((item: any) => !!item.examPDF).length ? (
        <section className="section-resume">
          <div className="title-resume">
            {translate('medicalRecord.examsAndProcedimentos')}
          </div>
          <div className="content-resume exams-and-procedures">
            {prescriptions
              .filter((item: any) => !!item.examPDF)
              .map((exam: any) => (
                <N.CardPrescriptionExams key={exam.uuid}>
                  <h1>{exam.codigoValidacao}</h1>
                  <span>
                    {`${translate('medicalRecord.issuedOn')} ${moment(
                      exam.createdAt
                    ).format('DD/MM/YYYY')}`}
                  </span>
                  <Button
                    type="button"
                    color="primary-light"
                    onClick={() => window.open(exam.examPDF, '_blank')}
                    styles={{ width: '100%' }}
                  >
                    {translate('medicalRecord.viewExams')}
                  </Button>
                </N.CardPrescriptionExams>
              ))}
          </div>
        </section>
      ) : null}

      {exams && exams.length ? (
        <section className="section-resume">
          <div className="title-resume">
            {translate('medicalRecord.images')}
          </div>
          <div className="content-resume">
            <N.AttachmentsList>
              {exams &&
                exams.map((exam: any, index: number) => {
                  const isImage = exam.type

                  return isImage !== '.pdf' ? (
                    <button
                      type="button"
                      className="images"
                      onClick={() => window.open(exam.location, '_blank')}
                    >
                      <img src={exam.location} alt="Imagem de um exame" />
                      <span>
                        {`${translate('medicalRecord.file')} ${index + 1}`}
                      </span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="files"
                      onClick={() => window.open(exam.location, '_blank')}
                    >
                      <img
                        src={pdfIcon}
                        alt="Icone que representa um arquivo PDF"
                      />
                      <span>
                        {`${translate('medicalRecord.file')} ${index + 1}`}
                      </span>
                    </button>
                  )
                })}
            </N.AttachmentsList>
          </div>
        </section>
      ) : null}
    </S.TimeResumeContent>
  )
}

export default TimeLineResume

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Avatar, Modal } from '@material-ui/core'
import getAge from 'utils/getAge'
import { convertHeight, calcIMC } from 'utils/convert'

import * as Global from 'global/styles.global'
import { Header, Card, Loading, Footer, Button } from 'components'
import { useUser } from 'context/user/user.context'
import { alertSuccess } from 'utils/toast'
import { translate } from 'locales'
import Resume from './Resume'
import Psico from './Psico'
import Nutri from './Nutri'
import FollowUp from './FollowUp'

import { findAllMedicalRecords, savePrescription } from './services'
import { handlePrescription } from '../New/services'

// Styles
import * as S from './styles'
import { menuItems } from './utils'

interface IRecords {
  medicalRecords?: any
  medicalRecordsPsicology?: any
  user?: any
  cellphones?: string[]
  address?: any
}

const Closed = () => {
  const { id } = useParams<{ id: string }>()
  const { userData }: any = useUser()
  const specialties = userData?.specialties

  const [loading, setLoading] = useState(true)
  const [selectedMenu, setSelectedMenu] = useState(0)
  const [records, setRecords] = useState<IRecords>({})
  const [state, setState] = useState({
    showModal: false,
    message: '',
    url: '',
  })

  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)

  useEffect(() => {
    findAllMedicalRecords(id, setRecords, setLoading, page, setTotalPages)
    // eslint-disable-next-line
  }, [page])

  const patientName = records?.user?.name?.split(' ') || []

  /* eslint-disable */
  useEffect(() => {
    const eventExec = async (event: any) => {
      const validEvents = ['cancel', 'excluded', 'prescricao']

      if (validEvents.includes(event.data.type)) {
        setState({ ...state, showModal: false })

        if (event.data.type === 'excluded') {
          return alertSuccess(translate('medicalRecord.removeSuccess'))
        }

        if (event.data.type === 'cancel') {
          return alertSuccess(translate('medicalRecord.cancelSuccess'))
        }

        if (event.data.type === 'prescricao') {
          await savePrescription(event.data)
          return alertSuccess(translate('medicalRecord.finishedSuccess'))
        }
      }
    }

    window.addEventListener('message', eventExec, false)

    return () => {
      window.removeEventListener('message', eventExec, false)
    }
  }, [window.addEventListener])
  /* eslint-enable */

  return (
    <Global.Container>
      {loading && <Loading title={translate('medicalRecord.loading')} />}
      <Header />
      <S.GridContent container xl={12}>
        <Grid item xs={12} md={3} style={{ padding: '2px 6px' }}>
          <Card styles={{ padding: 0 }}>
            <S.TopContent>
              <h1>{translate('medicalRecord.medicalRecords')}</h1>
              <h4>{translate('medicalRecord.info')}</h4>
              <Button
                styles={{ width: '100%', marginTop: 20 }}
                type="button"
                color="primary-light"
                onClick={() =>
                  handlePrescription(
                    {
                      patient: {
                        ...records?.user,
                      },

                      id: records?.user?.id,
                    },
                    state,
                    setLoading,
                    setState
                  )
                }
              >
                {translate('medicalRecord.remake')}
              </Button>
            </S.TopContent>
            <S.Menu>
              <ul>
                {menuItems.map((item: any, key: number) => (
                  <S.MenuList
                    key={item.id}
                    className={key === selectedMenu ? 'active' : ''}
                  >
                    <button
                      type="button"
                      onClick={() => setSelectedMenu(key)}
                      disabled={
                        (key === 1 &&
                          specialties.filter((i: any) => i.slug === 'psicologo')
                            .length === 0) ||
                        (key === 2 &&
                          specialties.filter(
                            (i: any) => i.slug === 'nutricionista'
                          ).length === 0)
                      }
                    >
                      {item.title}
                    </button>
                  </S.MenuList>
                ))}
              </ul>
            </S.Menu>
          </Card>
        </Grid>
        <Grid item xs={12} md={9} style={{ padding: '2px 6px' }}>
          <Card>
            <S.PatientContent>
              <div className="patient-data">
                <div className="cont-left">
                  <Avatar style={{ width: 100, height: 100, fontSize: 30 }}>
                    {(patientName.length && patientName[0].slice(0, 1)) || ''}
                    {(patientName.length &&
                      patientName[patientName.length - 1].slice(0, 1)) ||
                      ''}
                  </Avatar>
                </div>
                <div className="cont-right">
                  <h3>{records?.user?.name || ''}</h3>
                  <p>
                    <span>{translate('medicalRecord.age')} </span>
                    {`${getAge(
                      records?.user?.personal_data?.birthdate || ''
                    )} anos`}
                    <br />
                    <span>{translate('medicalRecord.gender')} </span>

                    {(records?.user?.personal_data?.gender === 'male' &&
                      translate('medicalRecord.male')) ||
                      ''}
                    {(records?.user?.personal_data?.gender === 'female' &&
                      translate('medicalRecord.female')) ||
                      ''}
                    {(records?.user?.personal_data?.gender === 'other' &&
                      translate('medicalRecord.other')) ||
                      ''}
                    <br />
                    <span>{translate('medicalRecord.weight')}</span>
                    {`${records?.user?.medical_record?.weight || 0} kg`}
                    <br />
                    <span>{translate('medicalRecord.height')}</span>
                    {`${convertHeight(
                      records?.user?.medical_record?.height
                    )} m`}
                    <br />
                    <span>IMC: </span>
                    {`${calcIMC(
                      records?.user?.medical_record?.height,
                      records?.user?.medical_record?.weight
                    )} kg/m2`}
                  </p>
                </div>
              </div>
              <Global.Line />
              {selectedMenu === 0 && (
                <Resume
                  medicalRecords={records?.medicalRecords}
                  count={totalPages}
                  setPage={(
                    event: React.ChangeEvent<unknown>,
                    value: number
                  ) => {
                    setPage(value)
                  }}
                />
              )}
              {selectedMenu === 1 && <Psico userUUID={id} />}
              {selectedMenu === 2 && <Nutri userUUID={id} />}
              {selectedMenu === 3 && <FollowUp userUUID={id} />}
            </S.PatientContent>
          </Card>
        </Grid>
      </S.GridContent>
      <Footer />
      {state.showModal && (
        // @ts-ignore
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          open={state.showModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ width: '80%', height: '90vh' }}>
            <iframe
              title={translate('medicalRecord.nexoData')}
              width="100%"
              height="100%"
              src={state.url}
            />
          </div>
        </Modal>
      )}
    </Global.Container>
  )
}

export default Closed

import React, { useState } from 'react'
import { telephoneMask } from 'js-essentials-functions'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { Card, Button, ModalShow } from '..'
import api from '../../configs/api'

import playIcon from '../../assets/icons/play.svg'
import whatsappIcon from '../../assets/icons/whatsapp.svg'

interface NextScheduleProps {
  // eslint-disable-next-line react/require-default-props
  styles?: Object
  nextSchedule:
    | {
        uuid: string
        date: string
        cellphone: string
      }
    | null
    | undefined
}

const NextScheduleCard = ({ nextSchedule, styles }: NextScheduleProps) => {
  const [nextScheduleCanceled, setNextScheduleCanceled] = useState(false)
  const [descriptionCancelation, setDescriptionCancelation] = useState('')
  const [showModal, setShowModal] = useState('none')
  const history = useHistory()

  const handleShowModal = () => {
    // @ts-ignore
    setShowModal('block')
  }

  const hideModal = () => {
    // @ts-ignore
    setShowModal('none')
  }

  const handleConfirmModal = () => {
    const cancelSchedule = async () => {
      try {
        await api.post('v1/scheduling/process', {
          scheduling_uuid: nextSchedule?.uuid,
          cancel_service_description: descriptionCancelation,
          status: 3,
        })

        setNextScheduleCanceled(true)
      } catch (err) {
        //
      }

      hideModal()
    }

    cancelSchedule()
  }

  return (
    <>
      <Card styles={{ ...styles }}>
        {!nextSchedule && (
          <p>O paciente não possui mais consultas agendadas.</p>
        )}
        {nextScheduleCanceled && <p>A próxima consulta foi cancelada.</p>}
        {nextSchedule && !nextScheduleCanceled && (
          <>
            <div className="card-content-item" style={{ marginBottom: 25 }}>
              <span>Próxima Consulta</span>
              <span>
                {moment.utc(nextSchedule?.date).format('DD/MM/YYYY, hh[h]mm')}
              </span>
            </div>
            <div className="card-content-item" style={{ marginBottom: 25 }}>
              <span>WhatsApp</span>
              <span>
                {nextSchedule && telephoneMask(nextSchedule?.cellphone)}
              </span>
            </div>
            <Button
              type="button"
              color="success"
              onClick={
                () => history.push(`/novo-prontuario/${nextSchedule.uuid}`)
                // eslint-disable-next-line react/jsx-curly-newline
              }
              icon={playIcon}
            >
              Iniciar Atendimento
            </Button>
            <Button
              type="button"
              color="link-danger"
              onClick={() => handleShowModal()}
              styles={{ marginTop: 10 }}
            >
              Cancelar Atendimento
            </Button>
            <hr />
            <Button
              type="button"
              color="warning"
              onClick={() => {}}
              styles={{ marginTop: 20 }}
              icon={whatsappIcon}
              iconStyles={{ paddingRight: 15 }}
            >
              Iniciar CHAT
            </Button>
          </>
        )}
      </Card>

      <div className="overlay" style={{ display: showModal }} />
      <div className="modal-wrapper" style={{ display: showModal }}>
        <div className="info-container">
          <ModalShow
            description={descriptionCancelation}
            setDescription={setDescriptionCancelation}
            closeModal={hideModal}
            handleConfirm={handleConfirmModal}
          />
        </div>
      </div>
    </>
  )
}

export default NextScheduleCard

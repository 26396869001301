/* eslint-disable */
import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

// Contexts
import { useSignIn } from '../context/authentication/signin.context'
import { SchenduleConfigProvider } from '../context/schenduleConfig/schenduleConfig.context'
import { SchenduleProvider } from '../context/schendule/schendule.context'
import { useUser } from '../context/user/user.context'

// Containers
import Login from 'containers/Authentication/Login'
import Dashboard from 'containers/Dashboard/Home'
import Profile from 'containers/Dashboard/Profile'
import NewMedicalRecord from 'containers/MedicalRecord/New'
import NewMedicalRecordPsychologist from 'containers/MedicalRecord/NewAnalysis'
import ScheduleConfiguration from 'containers/Schedule/Configuration'
import Patients from 'containers/Patients/History'
import Rating from 'containers/Rating'
import PatientDetails from 'containers/MedicalRecord/Closed'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { verifySignIn } = useSignIn()
  const { verifyUserStorage } = useUser()

  useEffect(() => {
    verifyUserStorage()
  }, [])

  return (
    <SchenduleConfigProvider>
      <SchenduleProvider>
        <Route
          {...rest}
          render={(props) =>
            verifySignIn() ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: {
                    from: props.location,
                  },
                }}
              />
            )
          }
        />
      </SchenduleProvider>
    </SchenduleConfigProvider>
  )
}

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/perfil" component={Profile} />
        <PrivateRoute path="/pacientes" component={Patients} />
        <PrivateRoute path="/avaliados" component={Rating} />
        <PrivateRoute path="/prontuarios/:id" component={PatientDetails} />
        <PrivateRoute
          path="/configurar-agenda"
          component={ScheduleConfiguration}
        />
        <PrivateRoute
          path="/novo-prontuario/:scheduleUUID"
          component={NewMedicalRecord}
        />
        <PrivateRoute
          path="/:areaName/novo-prontuario/:scheduleUUID"
          component={NewMedicalRecordPsychologist}
        />
        {/* <Route path="*" component={Page404} /> */}
      </Switch>
    </Router>
  )
}

export default Routes

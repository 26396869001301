import React from 'react'
import moment from 'moment'
import api from 'configs/api'

// Interfaces
import { UserProps } from 'context/user/user.types'
import { IDocSchedule, IScheduling, IMetrics } from './Interfaces'

export const findUserData = async (
  setUserData: React.Dispatch<React.SetStateAction<UserProps>>
) => {
  const locales = window.localStorage.getItem('@StarBem:lang')
  const valueLocale = locales === 'pt' ? 'pt-BR' : locales || 'pt-BR'
  try {
    const find = await api.get('/doctors/v1/users/me', {
      headers: {
        'Accept-Language': valueLocale,
      },
    })

    const user = find.data.doctor

    const dataInStorage: any = window.localStorage.getItem('@StarBem:user')
    const dataPase = JSON.parse(dataInStorage)

    const dataUser = {
      ...dataPase,
      ...user,
    }

    setUserData(dataUser)
    window.localStorage.setItem('@StarBem:user', JSON.stringify(dataUser))
    return { status: true, code: 200 }
  } catch (err: any) {
    return { status: false, code: err?.response?.data?.status }
  }
}

export const findMetrics = async (
  setMetrics: React.Dispatch<React.SetStateAction<IMetrics>>,
  month = new Date().getMonth() + 1,
  year = new Date().getFullYear()
) => {
  try {
    const dataInStorage: any = window.localStorage.getItem('@StarBem:user')
    const dataPase = JSON.parse(dataInStorage)

    const find = await api.get(
      `/schedulings/v1/doctor/${dataPase.id}/metrics?month=${month}&year=${year}`
    )

    const metrics = find.data
    setMetrics(metrics)
  } catch (err) {
    //
  }
}

export const findDoctorSchedule = async (
  setDoctorSchendule: React.Dispatch<React.SetStateAction<IDocSchedule[]>>
) => {
  try {
    const find = await api.get('/doctor/v1/schedule')

    const { items } = find.data
    // eslint-disable-next-line
    const tempArray: IDocSchedule[] = []

    items &&
      // deepcode ignore CallbackShouldReturn: resolução apresentada não fazia sentido
      // eslint-disable-next-line
      items.map((schedule: any) => {
        const data = {
          daysOfWeek: [schedule.ordernar],
          startTime: schedule.start_time,
          endTime: schedule.end_time,
        }

        tempArray.push(data)
      })

    setDoctorSchendule(tempArray)
    return true
  } catch (err) {
    return false
  }
}

export const schedulingListFormated = (appointments: any) => {
  const todayPatients: any = []
  const tempScheduling: IScheduling[] = []

  appointments &&
    // deepcode ignore CallbackShouldReturn: solução apresentada não fazia sentido
    // eslint-disable-next-line
    appointments.map((schedule: any) => {
      const scheduleDate = moment(schedule?.from_date_time).format('YYYY-MM-DD')

      const todayDate = moment(new Date()).format('YYYY-MM-DD')

      if (scheduleDate === todayDate) {
        const data = {
          uuid: schedule.id,
          date: schedule.from_date_time,
          name: schedule.user_name,
          areaId: schedule.specialty_id,
          areaName: schedule.specialty_name,
        }

        todayPatients.push(data)
      }

      const nameSplit = String(schedule.user_name).split(' ')
      const patientName = nameSplit.length
        ? `${nameSplit[0]} ${nameSplit[1]} - ${moment
            .utc(schedule?.from_date_time)
            .format('HH:mm')} às ${moment
            .utc(schedule?.to_date_time)
            .format('HH:mm')}`
        : `${nameSplit[0]}`

      const data: any = {
        start: schedule?.from_date_time,
        end: schedule?.to_date_time,
        display: 'background',
        title: patientName,
        id: schedule?.id,
        areaId: schedule?.specialty_id,
        areaName: schedule?.specialty_name,
      }

      tempScheduling.push(data)
    })

  return {
    schedulingList: tempScheduling,
    todayPatientsList: todayPatients,
  }
}
